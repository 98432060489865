import { createAsyncThunk } from '@reduxjs/toolkit';

import client from 'client';
import { CreatorProfileRollsDto } from 'types/creator-profile-rolls.type';
import { CreatorProfileDto } from 'types/creator-profile.type';
import { CreatorDto } from 'types/creator.type';

export const getCreators = createAsyncThunk<CreatorDto[], undefined>(
  'GET/creators/fan',
  async (_, { rejectWithValue }) => {
    try {
      const response = await client.get(`/creators/fan`, {
        params: { order: 'random', view: 'avatar_with_preview' },
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getProfile = createAsyncThunk<
  CreatorProfileDto,
  { creator_id?: string; username?: string; isPublic?: boolean }
>(
  'GET/creators/{user_id}/fan',
  async ({ creator_id, username, isPublic = false }, { rejectWithValue }) => {
    try {
      const config: any = {};
      const url = isPublic
        ? `/creators/unknown/public`
        : `/creators/${creator_id || 'unknown'}/fan`;
      if (!creator_id) {
        config.params = { username };
      }
      const response = await client.get(url, config);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getCreatorRoll = createAsyncThunk<
  CreatorProfileRollsDto,
  { roll_id: string; page: number; size: number; isPublic?: boolean }
>(
  'GET/rolls/{roll_id}/fan',
  async ({ roll_id, page, size, isPublic = false }, { rejectWithValue }) => {
    const url = isPublic ? `/rolls/${roll_id}/public` : `/rolls/${roll_id}/fan`;
    try {
      const response = await client.get(url, {
        params: { subscriptionsPage: page, subscriptionsSize: size },
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const pageCreatorRoll = createAsyncThunk<
  CreatorProfileRollsDto,
  { roll_id: string; page: number; size: number }
>(
  'GET/rolls/{roll_id}/fan?page',
  async ({ roll_id, page, size }, { rejectWithValue }) => {
    try {
      const response = await client.get(`/rolls/${roll_id}/fan`, {
        params: { subscriptionsPage: page, subscriptionsSize: size },
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const reportCreatorContent = createAsyncThunk<
  unknown,
  { roll_id: string; content_id: string; reason: string }
>(
  'POST/rolls/{roll_id}/contents/{content_id}/reports',
  async ({ roll_id, content_id, reason }, { rejectWithValue }) => {
    try {
      const response = await client.post(
        `/rolls/${roll_id}/contents/${content_id}/reports`,
        { reason }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const updateContentLastViewed = createAsyncThunk<
  object,
  { user_id: string; sub_id: string}
>(
  'PATCH/users/{user_id}/subscriptions/{sub_id}',
  async ({ user_id, sub_id }, { rejectWithValue }) => {
    try {
      const response = await client.patch(
        `/users/${user_id}/subscriptions/${sub_id}`,
        { "lastViewedAt": new Date().toISOString() }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);
