import { initializeApp } from '@firebase/app';
import {
  getFirestore,
  doc,
  collection,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  where,
  orderBy,
  limitToLast,
  startAt,
} from '@firebase/firestore';
import { getAuth, signInWithCustomToken, signOut, User } from '@firebase/auth';
import { Conversation } from 'app/messages/types/conversation.type';
import { getDatabase, ref, onValue } from '@firebase/database';
import { FIREBASE_USER } from 'constants/local-storage';
import { MessageDto } from 'app/messages/types/message.type';

// See: https://support.google.com/firebase/answer/7015592
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const database = getDatabase(app);

export async function firebaseSignIn(token: string): Promise<User | null> {
  const saved = localStorage.getItem(FIREBASE_USER);
  if (saved) {
    return JSON.parse(saved);
  }

  try {
    const { user } = await signInWithCustomToken(auth, token);
    localStorage.setItem(FIREBASE_USER, JSON.stringify(user));
    return user;
  } catch (error: any) {
    console.log(error);
  }

  localStorage.removeItem(FIREBASE_USER);
  return null;
}

export async function firebaseSignOut() {
  try {
    localStorage.removeItem(FIREBASE_USER);
    await signOut(auth);
    return true;
  } catch (error: any) {
    console.log(error);
  }

  localStorage.removeItem(FIREBASE_USER);
  return false;
}

export async function getFirestoreConversation(conversation_id: string) {
  const docRef = doc(firestore, 'conversations', conversation_id);
  const docSnap = await getDoc(docRef);

  if (!docSnap.exists()) {
    return null;
  }

  return docSnap.data();
}

export async function getFirestoreUnreadConversationMessages(
  conversation_id: string
) {
  try {
    const messagesRef = collection(
      firestore,
      'conversations',
      conversation_id,
      'messages'
    );

    const q = await query(messagesRef, where('seenAt', '==', null));
    const messagesSnap = await getDocs(q);

    if (messagesSnap.empty) {
      return null;
    }

    return messagesSnap.docs.map((d) => d.data());
  } catch (err) {
    console.log(err);
  }
}

export async function getFirestoreAdditionalMessages(
  conversation_id: string,
  { created }: { created: number }
) {
  const messagesRef = collection(
    firestore,
    'conversations',
    conversation_id,
    'messages'
  );

  const end = new Date(created / 1000000);

  const q = await query(
    messagesRef,
    where('createdAt', '<', end),
    orderBy('createdAt'),
    limitToLast(20)
  );
  const messagesSnap = await getDocs(q);

  if (messagesSnap.empty) {
    return null;
  }

  return messagesSnap.docs.map((d) => d.data());
}

export async function listenFirestoreMessageChange(
  conversation_id: string,
  onMessageLoaded: (data: MessageDto) => void
) {
  const messagesRef = collection(
    firestore,
    'conversations',
    conversation_id,
    'messages'
  );

  return await onSnapshot(messagesRef, {
    next: (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === 'modified') {
          onMessageLoaded(change.doc.data() as MessageDto);
        }
      });
    },
  });
}

export async function listenConversationList(
  userId: string,
  onConversationsLoaded: (docs: Conversation[]) => any
) {
  const conversationsRef = collection(
    firestore,
    'usersConversations',
    userId,
    'conversations'
  );

  const q = query(conversationsRef, orderBy('lastMessageSentAt', 'desc'));

  return await onSnapshot(q, {
    next: (snapshot) =>
      onConversationsLoaded(
        snapshot.docs.map((d) => d.data()) as Conversation[]
      ),
  });
}

export async function listenConversation(
  conversation_id: string,
  { date }: { date: number },
  onMessage: (docs: MessageDto[]) => any
) {
  const messagesRef = collection(
    firestore,
    'conversations',
    conversation_id,
    'messages'
  );

  const start = new Date(date / 1000000);

  const q = await query(messagesRef, orderBy('createdAt'), startAt(start));

  return await onSnapshot(q, {
    next: (snapshot) =>
      onMessage(snapshot.docs.map((d) => d.data()) as MessageDto[]),
  });
}

export const getStatus = async (
  creatorId: string,
  onStatus: (value: any) => void
) => {
  const databaseRef = ref(database, `status/${creatorId}`);
  return await onValue(databaseRef, (snapshot) => {
    onStatus(snapshot.val());
  });
};

export const getCreatorAvatar = async (
  creatorId: string,
  onCreator: (value: any) => void
) => {
  const databaseRef = ref(database, `/creators/${creatorId}`);
  return await onValue(databaseRef, (snapshot) => {
    onCreator(snapshot.val());
  });
};
