import { createAsyncThunk } from '@reduxjs/toolkit';

import client from 'client';
import { CreatorDto } from 'types/creator.type';

export const findCreators = createAsyncThunk<CreatorDto[], { q: string }>('GET/creators/fan/search', async ({ q }, { rejectWithValue }) => {
  try {
    const response = await client.get(`/creators/fan`, { params: { search: q, order: 'username', view: 'avatar_only' } });
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const findTrendingCreators = createAsyncThunk<CreatorDto[], undefined>('GET/creators/fan/trending', async (_, { rejectWithValue }) => {
  try {
    const response = await client.get(`/creators/fan`, { params: { order: 'trending', view: 'avatar_only' } });
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});
