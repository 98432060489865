import { createSlice } from '@reduxjs/toolkit';

import { CreatorsState } from 'app/auth/types/auth-state.type';

import {
  getCreatorRoll,
  getCreators,
  getProfile,
  pageCreatorRoll,
  reportCreatorContent,
} from './creators.actions';

import {
  getRollAssets,
  getRollPreviews,
  getRollPreviewsAssets,
} from 'handlers/mutate-roll-assets';

const initialState: CreatorsState = {
  allCreators: null,
  creatrosIndex: 0,
  creators: null,
  profile: null,
  page: 1,
  profileRoll: null,
  rollAssets: null,
  rollPreviews: null,
  rollPreviewsAssets: null,
  isMoreRolls: true,
  pending: {
    creators: false,
    profile: false,
    profileRoll: false,
    reportRoll: false,
  },
  errors: {
    creators: null,
    profile: null,
    profileRoll: null,
    reportRoll: null,
  },
};

export const creatorsSlice = createSlice({
  name: 'creators',
  initialState,
  reducers: {
    cleanProfile: (state) => {
      state.profile = null;
    },
    cleanProfileRoll: (state) => {
      state.profileRoll = null;
      state.rollAssets = null;
      state.rollPreviews = null;
      state.rollPreviewsAssets = null;
      state.page = 1;
    },
    getCreatorsChunk: (state) => {
      const { allCreators, creatrosIndex, creators } = state;

      if (allCreators && creatrosIndex < allCreators.length) {
        const endIndex = creatrosIndex + 10;
        const chunk = allCreators.slice(creatrosIndex, endIndex);
        state.creatrosIndex = endIndex;
        state.creators = creators ? [...creators, ...chunk] : chunk;
      }
    },
    resetCreatorIndex: (state) => {
      state.creatrosIndex = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      // ============ GET CREATORS ============ //
      .addCase(getCreators.pending, (state) => {
        state.pending.creators = true;
        state.errors.creators = null;
      })
      .addCase(getCreators.fulfilled, (state, { payload }) => {
        state.pending.creators = false;
        state.creators = payload.slice(0, 10);
        state.allCreators = payload;
        state.creatrosIndex += 10;
      })
      .addCase(
        getCreators.rejected,
        (state, action: any & { payload: any }) => {
          state.pending.creators = false;
          state.errors.creators = action.payload.message;
        }
      )
      // ============ GET CREATOR PROFILE ============ //
      .addCase(getProfile.pending, (state) => {
        state.pending.profile = true;
        state.errors.profile = null;
        state.profile = null;
        state.profileRoll = null;
        state.errors.profileRoll = null;
      })
      .addCase(getProfile.fulfilled, (state, { payload }) => {
        state.pending.profile = false;
        state.profile = payload;
      })
      .addCase(getProfile.rejected, (state, action: any & { payload: any }) => {
        state.pending.profile = false;
        state.errors.profile = action.payload.message;
      })
      // ============ GET CREATOR ROLLS ============ //
      .addCase(getCreatorRoll.pending, (state) => {
        state.pending.profileRoll = true;
        state.errors.profileRoll = null;
        state.profileRoll = null;
      })
      .addCase(getCreatorRoll.fulfilled, (state, { payload }) => {
        state.pending.profileRoll = false;
        state.profileRoll = payload;
        const profile = state.profile;
        const roll = state.profileRoll;
        const subscriptions = payload.pagination.subscriptions;
        state.isMoreRolls =
          subscriptions.currentPage < subscriptions.totalPages;
        if (!roll || !profile) {
          return;
        }

        state.rollAssets = getRollAssets(profile, roll);
        state.rollPreviews = getRollPreviews(profile, roll);

        state.rollPreviewsAssets = getRollPreviewsAssets(profile, roll, true);
      })
      .addCase(
        getCreatorRoll.rejected,
        (state, action: any & { payload: any }) => {
          state.pending.profileRoll = false;
          state.errors.profileRoll = action.payload.message;
        }
      )
      // ============ GET CREATOR ROLLS ============ //
      .addCase(pageCreatorRoll.pending, (state) => {
        state.pending.profileRoll = true;
        state.errors.profileRoll = null;
      })
      .addCase(pageCreatorRoll.fulfilled, (state, { payload }) => {
        state.pending.profileRoll = false;
        state.profileRoll = payload;

        const profile = state.profile;
        const roll = state.profileRoll;

        if (!roll || !profile) {
          return;
        }

        if (!payload?.subscriptions?.length) {
          return state;
        }

        const subscriptions = payload.pagination.subscriptions;
        state.isMoreRolls =
          subscriptions.currentPage < subscriptions.totalPages;

        state.profileRoll = {
          ...state.profileRoll,
          subscriptions: [
            ...state.profileRoll.subscriptions,
            ...payload.subscriptions,
          ],
        };
        state.page = state.page + 1;
        state.rollAssets = [
          ...(state.rollAssets?.map((roll) => roll) ?? []),
          ...(getRollAssets(profile, roll)?.map((roll) => roll) ?? []),
        ];
        state.rollPreviewsAssets = [
          ...(state.rollPreviewsAssets?.map((roll) => roll) ?? []),
          ...(getRollPreviewsAssets(profile, roll)?.map((roll) => roll) ?? []),
        ];
      })
      .addCase(
        pageCreatorRoll.rejected,
        (state, action: any & { payload: any }) => {
          state.pending.profileRoll = false;
          state.errors.profileRoll = action.payload.message;
        }
      )
      // ============ REPORT CREATOR CREATOR ============ //
      .addCase(reportCreatorContent.pending, (state) => {
        state.pending.reportRoll = true;
      })
      .addCase(reportCreatorContent.fulfilled, (state) => {
        state.pending.reportRoll = false;
      })
      .addCase(
        reportCreatorContent.rejected,
        (state, action: any & { payload: any }) => {
          state.pending.reportRoll = false;
          state.errors.reportRoll = action.payload.message;
        }
      );
  },
});

export const {
  cleanProfileRoll,
  getCreatorsChunk,
  resetCreatorIndex,
  cleanProfile,
} = creatorsSlice.actions;
