import { firebaseSignIn } from 'firebase';
import { UserDto } from 'types/user.type';
import { GetUserDto } from 'types/get-user.type';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { SubscriptionDto } from 'types/subscription.type';
import { UserSession } from 'app/auth/types/user-session.type';

import client from 'client';

export const loginWithGoogle = createAsyncThunk<
  UserSession,
  { access_token: string; refresh_token: string }
>('POST/users/login-with-google', async (data, { rejectWithValue }) => {
  try {
    const response = await client.post<UserSession>(
      '/users/login-with-google',
      null,
      {
        params: {
          accessToken: data.access_token,
          refreshToken: data.refresh_token,
        },
      }
    );
    await firebaseSignIn(response.data.fbToken);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const loginWithApple = createAsyncThunk<UserSession, { code: string }>(
  'POST/users/login-with-apple',
  async (data, { rejectWithValue }) => {
    try {
      const response = await client.post<UserSession>(
        '/users/login-with-apple',
        null,
        {
          params: {
            code: data.code,
            useBundleId: false,
          },
        }
      );
      await firebaseSignIn(response.data.fbToken);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getUser = createAsyncThunk<GetUserDto, { user_id: string }>(
  'GET/users/{user_id}',
  async (data, { rejectWithValue }) => {
    try {
      const response = await client.get(`/users/${data.user_id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const updateUser = createAsyncThunk<
  Partial<UserDto>,
  { user: Partial<UserDto>; user_id: string }
>('PATCH/users/{user_id}', async (data, { rejectWithValue }) => {
  try {
    await client.patch(`/users/${data.user_id}`, data.user);
    return data.user;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const updateUserPaymentMethod = createAsyncThunk<
  Partial<UserDto>,
  { user: Pick<UserDto, 'defaultPaymentMethodId'>; user_id: string }
>('PATCH/users/{user_id}/payment-method', async (data, { rejectWithValue }) => {
  try {
    await client.patch(`/users/${data.user_id}`, data.user);
    return data.user;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const deleteUser = createAsyncThunk<string, { user_id: string }>(
  'DELETE/users/{user_id}',
  async (data, { rejectWithValue }) => {
    try {
      await client.delete(`/users/${data.user_id}`);
      return data.user_id;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getSubscriptions = createAsyncThunk<
  SubscriptionDto[],
  { user_id: string }
>('GET/users/{user_id}/subscriptions', async (data, { rejectWithValue }) => {
  try {
    const response = await client.get(`/users/${data.user_id}/subscriptions`);
    return response.data?.subscriptions;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const cancelSubscriptions = createAsyncThunk<
  string,
  { user_id: string; roll_id: string }
>('DELETE/users/{user_id}/subscriptions', async (data, { rejectWithValue }) => {
  try {
    await client.delete(`/users/${data.user_id}/subscriptions`, {
      params: { rollId: data.roll_id },
    });
    return data.roll_id;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});
