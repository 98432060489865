import { Asset } from "types/assets.type";

interface MakeGridProps {
  small: Asset[][];
  big: Asset[][];
  size:number
}

export const makeGrid = ({ small, big,size }: MakeGridProps):Asset[][] => {
    const assets = [];
  for (let i = 0; i < size; i++) {

    if (i % 12 === 0 || i % 12 === 1 || i % 12 === 2 ) {
      assets.push(small[i]);
      continue;
    }

    if (i % 12 === 3) {
      assets.push(big[i]);
      continue;
    }

    if (i % 12 === 4 ||i % 12 === 5 ) {
      assets.push(small[i]);
      continue;
    }

    if (i % 12 === 6 || i % 12 === 7 || i % 12 === 8) {
      assets.push(small[i]);
      continue;
    }

    if (i % 12 === 9 || i % 12 === 11) {
      assets.push(small[i]);
      continue;
    }
    if (i % 12 === 10) {
      assets.push(big[i]);
      continue;
    }
 
  }
  return assets;
};
