import { createAsyncThunk } from '@reduxjs/toolkit';
import client from 'client';

import {
  firebaseSignIn,
  getFirestoreAdditionalMessages,
  getFirestoreConversation,
  getFirestoreUnreadConversationMessages,
} from 'firebase';
import { createDateString } from 'handlers/create-date-string';

export const getConversation = createAsyncThunk<
  any,
  { conversation_id: string; fb_token: string }
>(
  'GET/conversations/{id}',
  async ({ fb_token, conversation_id }, { rejectWithValue }) => {
    try {
      await firebaseSignIn(fb_token);
      return await getFirestoreConversation(conversation_id);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getUnreadConversationMessages = createAsyncThunk<
  any,
  { conversation_id: string; fb_token: string }
>(
  'GET/conversations/{id}/messages',
  async ({ fb_token, conversation_id }, { rejectWithValue }) => {
    try {
      await firebaseSignIn(fb_token);
      return await getFirestoreUnreadConversationMessages(conversation_id);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getAdditionalMessages = createAsyncThunk<
  any,
  { conversation_id: string; fb_token: string; created: number }
>(
  'GET/conversations/{id}/additional-messages',
  async ({ fb_token, conversation_id, created }, { rejectWithValue }) => {
    try {
      await firebaseSignIn(fb_token);
      return await getFirestoreAdditionalMessages(conversation_id, { created });
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const freeMessageToConversation = createAsyncThunk<
  any,
  { conversation_id: string; text: string; }
>(
  'POST/conversations/{id}/messages/fan',
  async ({ text, conversation_id }, { rejectWithValue }) => {
    try {
      const response = await client.post(
        `/conversations/${conversation_id}/messages/fan`,
        { content: [{ type: 'text', text: text }] }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getMessageAssets = createAsyncThunk<
  any,
  { messageIds: string[]; conversationId: string }
>(
  'GET/rolls/{id}/assets/creator',
  async ({ messageIds, conversationId }, { rejectWithValue }) => {
    try {
      const response = await client.get(`/assets`, {
        params: { type: 'conversation', conversationId, messageIds },
        paramsSerializer: {
          indexes: null,
        },
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const readNewMessages = createAsyncThunk<
  any,
  { conversation_id: string; message_id: string }
>(
  'PATCH/conversations/{id}/messages/{mid}/fan',
  async (data, { rejectWithValue }) => {
    try {
      await client.patch(
        `/conversations/${data.conversation_id}/messages/${data.message_id}/fan`,
        {
          seenAt: createDateString(),
        }
      );
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);
