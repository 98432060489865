export enum ZIndexes {
  Internal_Default = 0,
  Internal_One = 1,
  Internal_Two = 2,

  External_Top_First = 6664,
  External_Top_Second = 6665,
  External_Top_Third = 6666,
  
  External_Modal_First = 9997,
  External_Modal_Second = 9998,
  External_Modal_Third = 9999,

  External_Max = 99999,
}