import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import client from "client";

export const updateProfilePicture = createAsyncThunk<
  any,
  { user_id: string; image: File }
>('POST/users/{user_id}/content', async (data, { rejectWithValue }) => {
  try {
    const response = await client.post(`/users/${data.user_id}/contents`, {
      contentType: 'user_profile',
      mediaFormat: data.image.type.replace('image/', ''),
    });

    const { contentId, presignedUrl } = response.data;

    const requestConfig = { headers: { 'Content-Type': '' } };
    await axios.put(presignedUrl, data.image, requestConfig);
    const asset_response = await client.post(`/users/${data.user_id}/assets`, {
      contentId: contentId,
    });
    return asset_response;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});