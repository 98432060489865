import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { WalletIcon, HelpIcon, SettingsIcon, ContactIcon } from 'assets/icons';

import { useDispatch, useSelector } from 'react-redux';
import { picturePendingSelector } from 'app/profile/store/profile.selectors';
import {
  accessTokenSelector,
  userPendingSelector,
} from 'app/auth/store/auth.selectors';
import { getUser } from 'app/auth/store/auth.actions';
import { updateProfilePicture } from 'app/profile/store/profile.actions';

import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import AppBackdrop from './app-backdrop';

import { GetUserDto } from 'types/get-user.type';
import { RoutesList } from 'enums/routes-list.enum';
import { IconButton } from '@mui/material';
import CropPictureModal from 'app/profile/crop-picture-modal';
import ChangeProfilePictureDrawer from 'app/profile/change-profile-picture-drawer';
import { handleMailTo } from 'handlers/handle-mailto';
import useQueryParams from 'hooks/use-query-params';
import { MENU_OPEN } from 'constants/frontend-contsants';
import { colors } from 'constants/colors';

const UserDrawer: FC<{
  user: GetUserDto | null;
  opened: boolean;
  handleOpen: () => void;
  handleClose: () => void;
}> = ({ user, opened, handleOpen, handleClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { navigateWithQuery } = useQueryParams();

  const access_token = useSelector(accessTokenSelector);
  const picture_pending = useSelector(picturePendingSelector);
  const user_pending = useSelector(userPendingSelector);
  const isPicturePending = useMemo(() => {
    return user_pending || picture_pending;
  }, [picture_pending, user_pending]);

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isCropOpen, setIsCropOpen] = useState(false);
  const [isPictureDrawerOpen, setIsPictureDrawerOpen] = useState(false);

  const navigateSettings = () => {
    navigateWithQuery({
      route: RoutesList.Settings_Default,
      param: MENU_OPEN,
      value: 'true',
    });
  };

  const navigateWallet = () => {
    navigateWithQuery({
      route: RoutesList.Wallet_Default,
      param: MENU_OPEN,
      value: 'true',
    });
  };
  const navigateHelp = () => window.open(process.env.REACT_APP_FAQ_URL);

  const onSelectImage = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files.length > 0) {
        setSelectedFile(e.target.files[0]);
      }
    },
    []
  );

  const onCloseCropModal = useCallback(() => {
    setSelectedFile(null);
  }, []);

  const handleOpenPictureDrawer = useCallback(() => {
    setIsPictureDrawerOpen(true);
  }, []);
  const handleClosePictureDrawer = useCallback(() => {
    setIsPictureDrawerOpen(false);
  }, []);

  const updatePicture = useCallback(
    async (croppedFile: File) => {
      if (!access_token) {
        return;
      }

      await dispatch<any>(
        updateProfilePicture({
          user_id: access_token.sub.userId,
          image: croppedFile,
        })
      );
      dispatch<any>(getUser({ user_id: access_token.sub.userId }));
    },
    [dispatch, access_token]
  );

  useEffect(() => {
    if (selectedFile) {
      setIsCropOpen(true);
    }
  }, [selectedFile]);

  useEffect(() => {
    if (picture_pending) {
      setIsCropOpen(false);
      setIsPictureDrawerOpen(false);
      setSelectedFile(null);
    }
  }, [picture_pending]);

  return (
    <>
      <SwipeableDrawer
        anchor="right"
        open={opened}
        onOpen={handleOpen}
        onClose={handleClose}
        swipeAreaWidth={25}
        disableSwipeToOpen={false}
      >
        <Box
          role="presentation"
          sx={{ padding: '26px', background: '#ACFB68', flex: '1 1' }}
        >
          <List>
            <ListItem disablePadding>
              <ListItemText
                secondary={`hi ${user?.username} 👋`}
                secondaryTypographyProps={{
                  component: 'h2',
                  color: colors.default.infoText,
                  textTransform: 'uppercase',
                  sx: { fontSize: '18px', fontFamily: 'Avenir' },
                }}
              />
            </ListItem>
            <ListItem disablePadding sx={{ margin: '25px 0' }}>
              <Badge
                color="primary"
                overlap="circular"
                badgeContent={
                  <IconButton
                    sx={{
                      background: 'rgba(39, 39, 39, 1) !important',
                      border: '2px solid rgba(172, 251, 104, 1) !important',
                    }}
                    onClick={handleOpenPictureDrawer}
                  >
                    <PhotoCameraIcon
                      color="primary"
                      sx={{ lineHeight: 0, fontSize: '14px' }}
                    />
                  </IconButton>
                }
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                componentsProps={{
                  badge: {
                    style: {
                      background: 'transparent',
                      padding: '12px',
                      zIndex: 99999,
                    },
                  },
                }}
              >
                <ListItemAvatar>
                  {isPicturePending && (
                    <AppBackdrop
                      styles={{
                        width: '121px',
                        height: '121px',
                        borderRadius: '50%',
                        background: '#3A3A3A',
                      }}
                    />
                  )}
                  {!isPicturePending && (
                    <Avatar
                      src={user?.profilePictureUrl}
                      sx={{
                        width: '121px',
                        height: '121px',
                        background: '#3A3A3A',
                        zIndex: 0,
                      }}
                    >
                      <Typography
                        variant="body1"
                        color="white"
                        sx={{
                          fontFamily: 'Modak',
                          fontSize: '66px',
                          textTransform: 'uppercase',
                          lineHeight: 0,
                          margin: '11px 0 0 6px',
                        }}
                      >
                        {user?.username?.substring(0, 1)}
                      </Typography>
                    </Avatar>
                  )}
                </ListItemAvatar>
              </Badge>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                variant="text"
                color="dark-gray"
                size="medium"
                onClick={navigateWallet}
              >
                <ListItemIcon color="inherit">
                  <WalletIcon />
                </ListItemIcon>
                <ListItemText primary={t('navigations.wallet')} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                variant="text"
                color="dark-gray"
                size="medium"
                onClick={navigateHelp}
              >
                <ListItemIcon color="inherit">
                  <HelpIcon />
                </ListItemIcon>
                <ListItemText primary={t('navigations.help')} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                variant="text"
                color="dark-gray"
                size="medium"
                onClick={navigateSettings}
              >
                <ListItemIcon color="inherit">
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary={t('navigations.settings')} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                variant="text"
                color="dark-gray"
                size="medium"
                onClick={handleMailTo}
              >
                <ListItemIcon color="inherit">
                  <ContactIcon />
                </ListItemIcon>
                <ListItemText primary={t('navigations.contact')} />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </SwipeableDrawer>

      {isCropOpen && selectedFile && (
        <CropPictureModal
          file={selectedFile}
          onClose={onCloseCropModal}
          udpatePicture={updatePicture}
        />
      )}

      <ChangeProfilePictureDrawer
        handleClose={handleClosePictureDrawer}
        handleOpen={handleOpenPictureDrawer}
        onSelectImage={onSelectImage}
        opened={isPictureDrawerOpen}
      />
    </>
  );
};

export default UserDrawer;
