import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  freeMessageToConversation,
  getAdditionalMessages,
  getConversation,
  getMessageAssets,
  getUnreadConversationMessages,
} from './message.actions';
import { Conversation } from '../types/conversation.type';
import { MessagesState } from '../types/messages-state';
import { MessageDto } from '../types/message.type';
import { StatusDto } from '../types/status.dto';
import { CreatorAvatarDto } from '../types/creator-avatar.dto';

const initialState: MessagesState = {
  creatorStatuses: null,
  creatorsAvatar: null,
  conversations: null,
  conversation: null,
  messages: null,
  rawMessages: null,
  lastMessages: null,
  assets: null,
  newMessagesCounter: 0,
  messageCreatedAt: 0,
  isFirstMessagePending: false,
  pending: {
    conversations: false,
    conversation: false,
    messages: false,
    sentMessage: false,
    assets: false,
  },
  errors: {
    errors: null,
  },
};

export const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    cleanMessageErrors:(state) =>{
      state.errors.errors = null
    },
    updateMessageWithContent: (
      state,
      { payload }: PayloadAction<MessageDto>
    ) => {
      if (!state.lastMessages || !state.rawMessages) {
        return;
      }
      if (state.lastMessages.find((mess) => mess.id === payload.id)) {
        state.lastMessages = state.lastMessages.map((mess) =>
          mess.id === payload.id ? payload : mess
        );
        return;
      }
      state.rawMessages = state.rawMessages.map((mess) =>
        mess.id === payload.id ? payload : mess
      );
    },
    setIsFirstMessagePending: (state, { payload }: PayloadAction<boolean>) => {
      state.isFirstMessagePending = payload;
    },
    setMessages: (state, { payload }: PayloadAction<MessageDto[]>) => {
      const createdAt =
        payload[0].createdAt.seconds * 1000000000 +
        payload[0].createdAt.nanoseconds;

      state.messageCreatedAt = createdAt;
      state.messages = payload;
    },
    updateLastMessages: (state, { payload }: { payload: MessageDto[] }) => {
      if (!payload) {
        return;
      }
      state.lastMessages = payload;

      if (!payload?.length) return;
    },
    updateConversations: (state, action: PayloadAction<Conversation[]>) => {
      if (!action?.payload) {
        return;
      }
      state.conversations = action.payload;
    },
    removeNewMessageCounter: (state) => {
      state.newMessagesCounter = 0;
    },
    cleanMessages: (state) => {
      state.messages = null;
      state.conversation = null;
      state.rawMessages = null;
      state.lastMessages = null;
      state.messageCreatedAt = 0;
    },
    updateStatuses: (
      state,
      { payload }: PayloadAction<{ status: StatusDto; creator: string }>
    ) => {
      if (state.creatorStatuses) {
        state.creatorStatuses[payload.creator] = payload.status;
      }
      if (!state.creatorStatuses) {
        state.creatorStatuses = { [payload.creator]: payload.status };
      }
    },
    updateCreatorsAvatar: (
      state,
      {
        payload,
      }: PayloadAction<{ creatorAvatar: CreatorAvatarDto; creator: string }>
    ) => {
      if (state.creatorsAvatar) {
        state.creatorsAvatar[payload.creator] = payload.creatorAvatar;
      }
      if (!state.creatorsAvatar) {
        state.creatorsAvatar = { [payload.creator]: payload.creatorAvatar };
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // ============ GET CONVERSATION ============ //
      .addCase(getConversation.pending, (state) => {
        state.pending.conversation = true;
        state.conversation = null;
        state.messages = null;
        state.rawMessages = null;
      })
      .addCase(getConversation.fulfilled, (state, { payload }) => {
        state.pending.conversation = false;
        state.conversation = payload;
      })
      .addCase(
        getConversation.rejected,
        (state, action: any & { payload: any }) => {
          state.pending.conversation = false;
          state.errors.errors = action.payload.message;
        }
      )
      // ============ GET UNREAD MESSAGES FROM CREATOR ============ //
      .addCase(getUnreadConversationMessages.pending, (state) => {
        state.pending.messages = true;
        state.newMessagesCounter = 0;
      })
      .addCase(
        getUnreadConversationMessages.fulfilled,
        (state, { payload }) => {
          state.pending.messages = false;

          if (!payload) {
            return;
          }

          state.newMessagesCounter = payload.length;
        }
      )
      .addCase(
        getUnreadConversationMessages.rejected,
        (state, action: any & { payload: any }) => {
          state.pending.messages = false;
          state.errors.errors = action.payload.message;
        }
      )
      // ============ GET CONVERSATION MESSAGES ============ //
      .addCase(getAdditionalMessages.pending, (state) => {
        state.pending.messages = true;
      })
      .addCase(getAdditionalMessages.fulfilled, (state, { payload }) => {
        state.pending.messages = false;
        if (!payload) {
          return;
        }

        const data = state.rawMessages
          ? [...(payload as any[]), ...state.rawMessages]
          : payload;
        if (!data.length) return;
        state.rawMessages = state.rawMessages
          ? [...(payload as any[]), ...state.rawMessages]
          : payload;
      })
      .addCase(
        getAdditionalMessages.rejected,
        (state, action: any & { payload: any }) => {
          state.pending.messages = false;
          state.errors.errors = action.payload.message;
        }
      )
      // ============ MESSAGE TO CONVERSATION ============ //
      .addCase(freeMessageToConversation.pending, (state) => {
        state.pending.sentMessage = true;
      })
      .addCase(freeMessageToConversation.fulfilled, (state) => {
        state.pending.sentMessage = false;
      })
      .addCase(
        freeMessageToConversation.rejected,
        (state, action: any & { payload: any }) => {
          state.pending.sentMessage = false;
          state.errors.errors = action.payload.message;
        }
      )
      // ============ GET MESSAGE ASSETS ============ //
      .addCase(getMessageAssets.pending, (state) => {
        state.pending.assets = true;
      })
      .addCase(getMessageAssets.fulfilled, (state, { payload }) => {
        state.pending.assets = false;
        state.assets = payload;
      })
      .addCase(
        getMessageAssets.rejected,
        (state, action: any & { payload: any }) => {
          state.pending.assets = false;
          state.errors.errors = action.payload.message;
        }
      );
  },
});

export const {
  setIsFirstMessagePending,
  updateLastMessages,
  updateConversations,
  removeNewMessageCounter,
  cleanMessages,
  setMessages,
  updateStatuses,
  updateMessageWithContent,
  updateCreatorsAvatar,
  cleanMessageErrors
} = messagesSlice.actions;
