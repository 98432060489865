import { createAsyncThunk } from '@reduxjs/toolkit';
import { MessageContentCheckoutDto } from 'app/messages/types/message-content-checkout.dto';
import { MessageSendCheckoutDto } from 'app/messages/types/message-send-checkout.dto';

import client from 'client';
import { CheckoutSessionDto } from 'types/checkout-session.dto';
import { PaymentCardDto } from 'types/payment-card.type';
import { PaymentMethodDTO } from 'types/payment-method.type';

export const createPaymentMethods = createAsyncThunk<PaymentMethodDTO, null>(
  'POST/payment-methods',
  async (_, { rejectWithValue }) => {
    try {
      const response = await client.post(`/payment-methods`, {
        bool: null,
        int: null,
        platform: 'web',
        creatorId: null,
        workflow: 'settings',
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getPaymentMethods = createAsyncThunk<PaymentCardDto[], null>(
  'GET/payment-methods',
  async (_, { rejectWithValue }) => {
    try {
      const response = await client.get(`/payment-methods`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const updatePaymentMethods = createAsyncThunk<
  PaymentCardDto[],
  PaymentCardDto
>('PATCH/payment-methods', async (_, { rejectWithValue }) => {
  try {
    const response = await client.patch(`/payment-methods`);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const deletePaymentMethods = createAsyncThunk<
  string,
  { card_id: string }
>('PATCH/payment-methods', async (data, { rejectWithValue }) => {
  try {
    await client.delete(`/payment-methods/${data.card_id}`);
    return data.card_id;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const viewTransactions = createAsyncThunk<any, { user_id: string }>(
  'GET/users/{id}/portal-session',
  async (data, { rejectWithValue }) => {
    try {
      const response = await client.get(
        `/users/${data.user_id}/portal-session?platform=web`
      );
      window.open(response.data.url);
      return response;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getMessageCheckout = createAsyncThunk<
  CheckoutSessionDto,
  MessageContentCheckoutDto | MessageSendCheckoutDto
>('POST/checkout/sessions/messages', async (data, { rejectWithValue }) => {
  try {
    const response = await client.post<CheckoutSessionDto>(
      `/checkout/sessions`,
      data
    );
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const getSubscriptionCheckout = createAsyncThunk<
  CheckoutSessionDto,
  { creator_id: string; roll_id: string }
>('POST/checkout/sessions', async (data, { rejectWithValue }) => {
  try {
    const response = await client.post<CheckoutSessionDto>(
      `/checkout/sessions`,
      {
        creator: {
          id: data.creator_id,
        },
        roll: {
          id: data.roll_id,
        },
        platform: 'web',
        version: '2.0.1',
        productType: 'roll_subscription',
      }
    );
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});
