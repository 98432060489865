import { Grid, styled } from '@mui/material';

export const GradientDiv = styled(Grid)<{ ownerState?: { absolute?: boolean } }>(
  ({ ownerState = { absolute: true } }) => ({
    background: ownerState.absolute ? 'linear-gradient(180deg, rgba(239, 153, 233, 1) 41px, rgba(239, 153, 233, 0) 100%)':
    'linear-gradient(0deg, rgba(239, 153, 233, 1) 41px, rgba(239, 153, 233, 0) 70%)' ,
    width: '100%',
    height: ownerState.absolute ? '172px' : '300px',
    position: ownerState.absolute ? 'absolute' : 'initial',
    left: 0,
    top: 0,
    zIndex: 0,
  })
);
