import React, { FC, Suspense, useEffect } from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';

import { RoutesList } from 'enums/routes-list.enum';
import { useDispatch, useSelector } from 'react-redux';

import {
  accessTokenSelector,
  sessionSelector,
  userErrorSelector,
  userSelector,
} from 'app/auth/store/auth.selectors';
import { UserSession } from 'app/auth/types/user-session.type';
import { getUser } from 'app/auth/store/auth.actions';
import AppBackdrop from 'components/app-backdrop';
import useWindowDimensions from 'hooks/use-window-dimensions';
import { SCREEN_SIZE_BORDER } from 'constants/frontend-contsants';
import StayTunedPage from 'components/stay-tuned-page';
import usePresence from 'hooks/use-presence';

// ======= private route ======= //
const PrivateRoute: FC<{ element: any; session: UserSession | null }> = ({
  element: Element,
  session,
}) => {
  return session ? (
    <Suspense fallback={<AppBackdrop />}>
      <Element />
    </Suspense>
  ) : (
    <Navigate to={RoutesList.Auth_SignIn} />
  );
};

// ======= public route ======= //
const PublicRoute: FC<{ element: any }> = ({ element: Element }) => (
  <Suspense fallback={<AppBackdrop />}>
    <Element />
  </Suspense>
);

// ======= pages ======= //
const AuthPages = React.lazy(
  () => import(/* webpackChunkName: "pages_auth" */ 'app/auth')
);
const OnboardingPages = React.lazy(
  () => import(/* webpackChunkName: "pages_onboarding" */ 'app/onboarding')
);
const SettingsPages = React.lazy(
  () => import(/* webpackChunkName: "pages_settings" */ 'app/settings')
);
const CreatorsPages = React.lazy(
  () => import(/* webpackChunkName: "pages_creators" */ 'app/creators')
);
const CreatorProfile = React.lazy(
  () =>
    import(/* webpackChunkName: "pages_profile" */ 'app/creators/profile.page')
);
const MessagesPages = React.lazy(
  () => import(/* webpackChunkName: "pages_messages" */ 'app/messages')
);
const SearchPages = React.lazy(
  () => import(/* webpackChunkName: "pages_search" */ 'app/search')
);
const WalletPages = React.lazy(
  () => import(/* webpackChunkName: "pages_wallet" */ 'app/wallet')
);
const ProfilePages = React.lazy(
  () => import(/* webpackChunkName: "pages_profile" */ 'app/profile')
);

const AppRoutes = () => {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const session = useSelector(sessionSelector);
  const access_token = useSelector(accessTokenSelector);
  const user = useSelector(userSelector);
  const userError = useSelector(userErrorSelector);

  useEffect(() => {
    if (!session || !access_token) {
      return;
    }

    dispatch<any>(getUser({ user_id: access_token.sub.userId }));
  }, [session, access_token, dispatch]);

  access_token && usePresence({ uid: access_token.sub.userId });

  if (userError) {
    return <StayTunedPage user={user} />;
  }

  return (
    <Routes>
      {/* PUBLIC */}
      <Route
        path={RoutesList.Auth_All}
        element={<PublicRoute element={AuthPages} />}
      />
      <Route
        path={RoutesList.Creators_Profile}
        element={<PublicRoute element={CreatorProfile} />}
      />

      {/* PRIVATE */}
      <Route
        path={RoutesList.Onboarding_All}
        element={<PrivateRoute session={session} element={OnboardingPages} />}
      />
      <Route
        path={RoutesList.Messages_All}
        element={<PrivateRoute session={session} element={MessagesPages} />}
      />
      <Route
        path={RoutesList.Search_All}
        element={<PrivateRoute session={session} element={SearchPages} />}
      />

      {width > SCREEN_SIZE_BORDER && (
        <Route
          path={RoutesList.Profile_All}
          element={<PrivateRoute session={session} element={ProfilePages} />}
        />
      )}
      {width <= SCREEN_SIZE_BORDER && (
        <>
          <Route
            path={RoutesList.Settings_All}
            element={<PrivateRoute session={session} element={SettingsPages} />}
          />
          <Route
            path={RoutesList.Wallet_All}
            element={<PrivateRoute session={session} element={WalletPages} />}
          />
        </>
      )}

      {/* DEFAULT */}
      {user && !user.username && (
        <Route
          path={RoutesList.All}
          element={<Navigate to={RoutesList.Onboarding_Default} />}
        />
      )}
      {!session && (
        <Route
          path={RoutesList.All}
          element={<Navigate to={RoutesList.Auth_Default} />}
        />
      )}

      <Route
        path={RoutesList.Creators_All}
        element={<PrivateRoute session={session} element={CreatorsPages} />}
      />
    </Routes>
  );
};

export default AppRoutes;
