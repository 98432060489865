import { RootState } from 'store';

export const sessionSelector = (state: RootState) => state.auth.session;
export const sessionErrorSelector = (state: RootState) =>
  state.auth.errors.session;
export const sessionPendingSelector = (state: RootState) =>
  state.auth.pending.session;
export const accessTokenSelector = (state: RootState) =>
  state.auth.access_token;
export const userSelector = (state: RootState) => state.auth.user;
export const userPendingSelector = (state: RootState) =>
  state.auth.pending.user;
export const userErrorSelector = (state: RootState) => state.auth.errors.user;
export const userSubscriptionsSelector = (state: RootState) =>
  state.auth.subscriptions;
export const userSubscriptionsPendingSelector = (state: RootState) =>
  state.auth.pending.subscriptions;
export const userSubscriptionsErrorSelector = (state: RootState) =>
  state.auth.errors.subscriptions;
export const cancelSubscriptionPending = (state: RootState) =>
  state.auth.pending.cancelSubscriptions;
