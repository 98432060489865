import { styled } from '@mui/material';
import { CSSProperties, FC } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const BackdropContainer = styled('div')<{
  styles: CSSProperties;
}>(({ styles }) => ({
  display: 'flex',
  flex: '1 1',
  justifyContent: 'center',
  alignItems: 'center',
  ...styles,
}));

const AppBackdrop: FC<{
  styles?: CSSProperties;
  color?: 'info' | 'inherit';
}> = ({ styles = {}, color = 'info' }) => (
  <>
    <br />
    <BackdropContainer styles={styles}>
      <CircularProgress color={color} />
    </BackdropContainer>
    <br />
  </>
);

export default AppBackdrop;
