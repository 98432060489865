import { IconButton, Toolbar, Typography, styled } from '@mui/material';
import { SCREEN_SIZE_BORDER } from 'constants/frontend-contsants';

import Grid from '@mui/material/Grid';
import AppHeader from 'components/app-header';

import { GreenLink } from 'components/styled/green-link';
import { GradientDiv } from 'components/styled/gradient-div';
import { CreatorContentContainer } from './styled/creator-content-container';
import { useTranslation } from 'react-i18next';
import { GetUserDto } from 'types/get-user.type';
import { ArrowBackIos } from '@mui/icons-material';
import { ZIndexes } from 'enums/z-indexes';
import useWindowDimensions from 'hooks/use-window-dimensions';
import { handleMailTo } from 'handlers/handle-mailto';

const StyledToolBar = styled(Toolbar)(({ theme }) => ({
  zIndex: ZIndexes.Internal_Two,
  [theme.breakpoints.down('md')]: {
    position: 'absolute',
    top: '25px',
  },
}));

const ErrorTextContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-end',
  paddingTop: '50px',
  '& .MuiTypography-root': {
    width: '90%',
  },
  [theme.breakpoints.up('md')]: {
    position: 'relative',
    top: '15%',
    '& .MuiTypography-root': {
      width: '60%',
    },
  },
}));

interface StayTunedPageProps {
  isErrorBoundary?: boolean;
  user: GetUserDto | null;
  onNavigateBack?: () => void;
}

const StayTunedPage = (props: StayTunedPageProps) => {
  const { isErrorBoundary = false, user, onNavigateBack } = props;

  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  return (
    <>
      {!isErrorBoundary && (
        <AppHeader
          user={user}
          display={{ xs: 'none', md: 'flex' }}
        />
      )}
      <CreatorContentContainer
        ownerState={{ isError: true, isBoundary: isErrorBoundary }}
      >
        <StyledToolBar>
          {onNavigateBack && (
            <IconButton color="info" size="large" onClick={onNavigateBack}>
              <ArrowBackIos sx={{ fontSize: '22px' }} />
            </IconButton>
          )}
        </StyledToolBar>
        <GradientDiv ownerState={{ absolute: width > SCREEN_SIZE_BORDER }} />
        <ErrorTextContainer>
          <Typography className="header" textAlign="center">
            {t('modals.headers.stay-tuned')}
          </Typography>
          <Typography variant="subtitle1" color="dark-white">
            {t('modals.texts.creator-content-unavailable')}
          </Typography>
          <br />

          <Typography variant="subtitle1" color="dark-white">
            {t('modals.texts.if-you-need-help')}
            &nbsp;
            <GreenLink onClick={handleMailTo}>
              {t('modals.texts.support-email')}
            </GreenLink>
          </Typography>
        </ErrorTextContainer>
      </CreatorContentContainer>
    </>
  );
};

export default StayTunedPage;
