import { configureStore } from '@reduxjs/toolkit';

import { authSlice } from 'app/auth/store/auth.slice';
import { creatorsSlice } from 'app/creators/store/creators.slice';
import { messagesSlice } from 'app/messages/store/messages.slice';
import { searchSlice } from 'app/search/store/search.slice';
import { walletSlice } from 'app/wallet/store/wallet.slice';
import { profileSlice } from 'app/profile/store/profile.slice';

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    creators: creatorsSlice.reducer,
    search: searchSlice.reducer,
    wallet: walletSlice.reducer,
    messages: messagesSlice.reducer,
    profile: profileSlice.reducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;