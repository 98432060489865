import { Grid, styled } from "@mui/material";

export const CreatorContentContainer = styled(Grid)<{ ownerState?: { isError: boolean, isBoundary?:boolean } }>(
    ({ theme, ownerState }) => ({
      position: 'relative',
      flexDirection: 'column',
      flex: '1 1 600px',
      [theme.breakpoints.up('md')]: {
        maxWidth: ownerState?.isError ? '700px' : 'initial',
        margin:ownerState?.isBoundary ? '0 auto' : 'initial',
        borderLeft: '1px solid rgba(159, 159, 159, .2)',
        borderRight: '1px solid rgba(159, 159, 159, .2)',
      },
    })
  );
  