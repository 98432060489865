import { createSlice } from '@reduxjs/toolkit';

import { SearchState } from 'app/auth/types/auth-state.type';

import { findCreators, findTrendingCreators } from './search.actions';

const initialState: SearchState = {
  creators: null,
  trending: null,
  pending: {
    creators: false,
    trending: false,
  },
  errors: {
    creators: null,
    trending: null,
  },
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // ============ FIND CREATORS ============ //
      .addCase(findCreators.pending, (state) => {
        state.pending.creators = true;
        state.errors.creators = null;
      })
      .addCase(findCreators.fulfilled, (state, { payload }) => {
        state.pending.creators = false;
        state.creators = payload && !payload.length ? null : payload;
      })
      .addCase(
        findCreators.rejected,
        (state, action: any & { payload: any }) => {
          state.pending.creators = false;
          state.errors.creators = action.payload.message;
        }
      )
      // ============ FIND TRENDING CREATORS ============ //
      .addCase(findTrendingCreators.pending, (state) => {
        state.pending.trending = true;
        state.errors.trending = null;
      })
      .addCase(findTrendingCreators.fulfilled, (state, { payload }) => {
        state.pending.trending = false;
        state.trending = payload && !payload.length ? null : payload;
      })
      .addCase(
        findTrendingCreators.rejected,
        (state, action: any & { payload: any }) => {
          state.pending.trending = false;
          state.errors.trending = action.payload.message;
        }
      );
  },
});
