import {
  Asset,
  CreatorAssetType,
  CreatorPreviewAssetType,
  PreviewAsset,
} from 'types/assets.type';
import { CreatorProfileRollsDto } from 'types/creator-profile-rolls.type';
import { CreatorProfileDto } from 'types/creator-profile.type';
import { makeGrid } from './make-grid';

export function isBigContent(type: CreatorPreviewAssetType | CreatorAssetType) {
  return [
    CreatorPreviewAssetType.BIG_PREVIEW,
    CreatorPreviewAssetType.LARGE,
    CreatorAssetType.BIG_BLURRED,
  ].includes(type);
}

export function getRollAssets(
  profile: CreatorProfileDto,
  roll: CreatorProfileRollsDto
): Asset[][] | null {
  const size: number = roll.subscriptions.length;

  if (size === 0) {
    return null;
  }

  const subs_small =
    roll.subscriptions?.map((sub) => {
      const videoLength =
        sub.assets.find((d) => d.mediaType === 'video')?.videoLengthSeconds ||
        0;

      return sub?.assets
        ?.filter((d) =>
          [
            CreatorAssetType.SMALL_BLURRED,
            CreatorPreviewAssetType.SMALL_PREVIEW,
          ].includes(d.assetType)
        )
        .map((asset) => ({
          id: asset.id,
          contentId: sub.contentId,
          createdAt: sub.createdAt,
          videoLength: videoLength,
          rollId: profile.roll?.id,
          content: asset.assetUrl,
          type: asset.mediaType,
          assetType: asset.assetType,
          rows: isBigContent(asset.assetType) ? 2 : 1,
          cols: isBigContent(asset.assetType) ? 2 : 1,
          locked: asset.assetType === CreatorAssetType.SMALL_BLURRED,
        }));
    }) || [];
  const subs_big =
    roll.subscriptions?.map((sub) => {
      const videoLength =
        sub.assets.find((d) => d.mediaType === 'video')?.videoLengthSeconds ||
        0;
      return sub?.assets
        ?.filter((d) =>
          [
            CreatorAssetType.BIG_BLURRED,
            CreatorPreviewAssetType.BIG_PREVIEW,
          ].includes(d.assetType)
        )
        .map((asset) => ({
          id: asset.id,
          contentId: sub.contentId,
          createdAt: sub.createdAt,
          videoLength: videoLength,
          rollId: profile.roll?.id,
          content: asset.assetUrl,
          type: asset.mediaType,
          assetType: asset.assetType,
          rows: isBigContent(asset.assetType) ? 2 : 1,
          cols: isBigContent(asset.assetType) ? 2 : 1,
          locked: asset.assetType === CreatorAssetType.BIG_BLURRED,
        }));
    }) || [];

  const small: Asset[][] = subs_small;
  const big: Asset[][] = subs_big;

  const assets = makeGrid({ small, big, size });

  return !assets.length ? null : assets;
}

export function getRollPreviews(
  profile: CreatorProfileDto,
  roll: CreatorProfileRollsDto
): PreviewAsset[][] | null {
  const size: number = roll.previews.length;

  if (size === 0) {
    return null;
  }

  const previews_small =
    roll.previews?.map((preview) => {
      const videoLength =
        preview.assets.find((d) => d.mediaType === 'video')
          ?.videoLengthSeconds || 0;

      return preview?.assets
        ?.filter((d) => d.assetType === CreatorPreviewAssetType.SMALL_PREVIEW)
        .map((asset) => ({
          id: asset.id,
          contentId: preview.contentId,
          createdAt: preview.createdAt,
          videoLength: videoLength,
          rollId: profile.roll?.id,
          content: asset.assetUrl,
          type: asset.mediaType,
          assetType: asset.assetType,
          rows: isBigContent(asset.assetType) ? 2 : 1,
          cols: isBigContent(asset.assetType) ? 2 : 1,
          locked: false,
        }));
    }) || [];
  const previews_big =
    roll.previews?.map((preview) => {
      const videoLength =
        preview.assets.find((d) => d.mediaType === 'video')
          ?.videoLengthSeconds || 0;

      return preview?.assets
        ?.filter((d) => d.assetType === CreatorPreviewAssetType.LARGE)
        .map((asset) => ({
          id: asset.id,
          contentId: preview.contentId,
          createdAt: preview.createdAt,
          videoLength: videoLength,
          rollId: profile.roll?.id,
          content: asset.assetUrl,
          type: asset.mediaType,
          assetType: asset.assetType,
          rows: isBigContent(asset.assetType) ? 2 : 1,
          cols: isBigContent(asset.assetType) ? 2 : 1,
          locked: false,
        }));
    }) || [];

  const small: PreviewAsset[][] = [...previews_small];
  const big: PreviewAsset[][] = [...previews_big];

  const assets = [];
  for (let i = 0; i < size; i++) {
    if (i % 3 === 0) {
      assets.push(big[i]);
      continue;
    }

    if (i % 3 === 1) {
      assets.push(small[i]);
      continue;
    }

    if (i % 3 === 2) {
      assets.push(small[i]);
      continue;
    }
  }
  return !assets.length ? null : assets;
}

export function getRollPreviewsAssets(
  profile: CreatorProfileDto,
  roll: CreatorProfileRollsDto,
  isFirst?: boolean
): any[][] | null {
  const subs_b =
    roll.subscriptions?.map((sub) => {
      const asset = sub?.assets?.find((d) =>
        d.mediaType === 'video'
          ? [CreatorAssetType.BIG_BLURRED, CreatorAssetType.ORIGINAL].includes(
              d.assetType
            )
          : [CreatorAssetType.BIG_BLURRED, CreatorAssetType.GALLERY].includes(
              d.assetType
            )
      );
      return asset
        ? [asset].map((asset) => ({
            id: asset.id,
            contentId: sub.contentId,
            rollId: profile.roll?.id,
            content: asset.assetUrl,
            type: asset.mediaType,
            assetType: asset.assetType,
            rows: 2,
            cols: 2,
            locked: asset.assetType === CreatorAssetType.BIG_BLURRED,
          }))
        : null;
    }) || [];
  const previews_b =
    roll.previews?.map((preview) => {
      const asset = preview?.assets?.find((d) =>
        d.mediaType === 'video'
          ? d.assetType === CreatorPreviewAssetType.ORIGINAL
          : d.assetType === CreatorPreviewAssetType.GALLERY
      );
      return asset
        ? [asset].map((asset) => ({
            id: asset.id,
            contentId: preview.contentId,
            rollId: profile.roll?.id,
            content: asset.assetUrl,
            type: asset.mediaType,
            assetType: asset.assetType,
            rows: 2,
            cols: 2,
            locked: false,
          }))
        : null;
    }) || [];

  return isFirst
    ? ([...previews_b, ...subs_b] as any[])
    : ([...subs_b] as any[]);
}
