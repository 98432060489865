export enum RoutesList {
  All = '*',
  Default = '/',

  Auth_Default = '/auth',
  Auth_All = '/auth/*',
  Auth_SignIn = '/auth/sign-in',
  Auth_SignIn_Short = '/sign-in',

  Onboarding_Default = '/onboarding',
  Onboarding_All = '/onboarding/*',

  // ============== profile =============== //
  Profile_Default = '/profile',
  Profile_All = '/profile/*',

  // ============== settings ============== //
  Settings_Default = '/profile/settings',
  Settings_All = '/profile/settings/*',
  Settings_Short_All = '/settings/*',
  Settings_Main = '/profile/settings',
  Settings_Main_Short = '/',
  Settings_UpdateUsername = '/profile/settings/username',
  Settings_UpdateUsername_Short = '/username',
  Settings_Account = '/profile/settings/account',
  Settings_Account_Short = '/account',
  Settings_Account_DeleteConfirm = '/profile/settings/account/delete-confirm',
  Settings_Account_DeleteConfirm_Short = '/account/delete-confirm',

  // ============== wallet ============== //
  Wallet_Default = '/profile/wallet',
  Wallet_All = '/profile/wallet/*',
  Wallet_Short_All = '/wallet/*',
  Wallet_Main = '/profile/wallet',
  Wallet_Main_Short = '/',
  Wallet_AddCard = '/profile/wallet/add-card',
  Wallet_AddCard_Short = '/add-card',

  // ============== creators ============== //
  Creators_Default = '/',
  Creators_All = '/*',
  Creators_Main = '/',
  Creators_Main_Short = '/',
  Creators_Profile = '/:username',
  Creators_Profile_Short = '/:username',

  // ============== search ============== //
  Search_Default = '/search',
  Search_All = '/search/*',

  // ============== conversations ============== //
  Messages_Default = '/conversations',
  Messages_All = '/conversations/*',
  Messages_Creator_Default = '/conversations/:conversation_id',
  Messages_Creator_New = '/conversations/new',
  Messages_Creator_New_Short = '/conversations/new',
  Messages_Creator_Short = '/:conversation_id',
}
