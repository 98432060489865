import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  SwipeableDrawer,
  Typography,
  styled,
} from '@mui/material';
import { colors } from 'constants/colors';
import { ZIndexes } from 'enums/z-indexes';
import { useTranslation } from 'react-i18next';

const StyledDrawer = styled(SwipeableDrawer)({
  zIndex: ZIndexes.External_Top_First,

  '& .MuiDrawer-paper': {
    maxWidth: '100%',
    width: '100%',
    borderRadius: '30px 30px 0 0',
    background: 'none',
    border: 'none',
    boxShadow: 'none',
    textAlign: 'center',
    height: '35dvh',
  },
});

const StyledList = styled(List)({
  width: '90%',
  margin: '0 auto',
  backgroundColor: colors.default.darkGrey,
  color: colors.default.info,
  borderRadius: '30px',
  boxShadow: '0px 9px 8px -2px rgba(50, 6, 26, 0.17)',
});

const StyledListItem = styled(ListItem)({
  justifyContent: 'center',
  padding: '20px 0',
});

const ChangePictureTitle = styled(Typography)({
  fontWeight: 800,
  fontSize: '12px',
  lineHeight: '16px',
  letterSpacing: '-0.3%',
  textAlign: 'center',

  '&.subtitle': {
    fontWeight: 500,
    opacity: 0.5,
  },
});

const ChangePictureButton = styled(Button)<{ component?: string }>({
  fontFamily: 'Avenir-Regular',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '20px',
  letterSpacing: '-0.3%',
  textAlign: 'center',
  textTransform: 'inherit',
  padding: '0',

  '&.cancel': {
    width: '90%',
    backgroundColor: '#2C2C2E',
    color: colors.default.secondary,
    padding: '20px 0',
    boxShadow: '0px 9px 8px -2px rgba(50, 6, 26, 0.17)',
    marginTop: '10px',
    fontWeight: 900,
  },
});

interface ChangeProfilePictureDrawerProps {
  opened: boolean;
  handleOpen: () => void;
  handleClose: () => void;
  onSelectImage: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ChangeProfilePictureDrawer = (props: ChangeProfilePictureDrawerProps) => {
  const { opened, handleClose, handleOpen, onSelectImage } = props;
  const { t } = useTranslation();

  return (
    <StyledDrawer
      onOpen={handleOpen}
      anchor="bottom"
      open={opened}
      onClose={handleClose}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <StyledList>
          <StyledListItem sx={{ flexDirection: 'column' }}>
            <ChangePictureTitle>
              {t('titles.change-profile-picture.title')}
            </ChangePictureTitle>
            <ChangePictureTitle className="subtitle">
              {t('titles.change-profile-picture.subtitle')}
            </ChangePictureTitle>
          </StyledListItem>
          <Divider />
          <StyledListItem>
            <ChangePictureButton component="label">
              {t('buttons.photo-library')}
              <label>
                <input
                  type="file"
                  value={''}
                  hidden
                  accept="image/*"
                  onChange={onSelectImage}
                />
              </label>
            </ChangePictureButton>
          </StyledListItem>
          <Divider />
          <StyledListItem>
            <ChangePictureButton component="label">
              {t('buttons.camera')}
              <input
                accept="image/*"
                id="icon-button-file"
                value={''}
                hidden
                type="file"
                capture="environment"
                onChange={onSelectImage}
              />
            </ChangePictureButton>
          </StyledListItem>
        </StyledList>
        <ChangePictureButton className="cancel" onClick={handleClose}>
          {t('buttons.cancel')}
        </ChangePictureButton>
      </Box>
    </StyledDrawer>
  );
};

export default ChangeProfilePictureDrawer;
