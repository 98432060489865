import { createSlice } from '@reduxjs/toolkit';

import { AuthState } from 'app/auth/types/auth-state.type';

import {
  cancelSubscriptions,
  deleteUser,
  getSubscriptions,
  getUser,
  loginWithApple,
  loginWithGoogle,
  updateUser,
  updateUserPaymentMethod,
} from './auth.actions';
import { GetUserDto } from 'types/get-user.type';
import { UserDto } from 'types/user.type';
import { ACCESS_TOKEN, SESSION } from 'constants/local-storage';

const session = localStorage.getItem(SESSION)
  ? JSON.parse(localStorage.getItem(SESSION) as string)
  : null;
const access_token = localStorage.getItem(ACCESS_TOKEN)
  ? JSON.parse(
      atob((localStorage.getItem(ACCESS_TOKEN) as string).split('.')[1])
    )
  : null;

const initialState: AuthState = {
  session,
  access_token,
  user: null,
  subscriptions: null,
  pending: {
    session: false,
    user: false,
    subscriptions: false,
    cancelSubscriptions: false,
  },
  errors: {
    session: null,
    user: null,
    subscriptions: null,
    cancelSubscriptions: null,
  },
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    cancelPending: (state) => {
      state.pending.cancelSubscriptions = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // ============ LOGIN GOOGLE ============ //
      .addCase(loginWithGoogle.pending, (state) => {
        state.pending.session = true;
        state.errors.session = null;
      })
      .addCase(loginWithGoogle.fulfilled, (state, { payload }) => {
        state.pending.session = false;
        state.session = payload;
        state.access_token = JSON.parse(atob(payload.token.split('.')[1]));
      })
      .addCase(
        loginWithGoogle.rejected,
        (state, action: any & { payload: any }) => {
          state.pending.session = false;
          state.errors.session = action.payload.message;
        }
      )
      // ============ LOGIN APPLE ============ //
      .addCase(loginWithApple.pending, (state) => {
        state.pending.session = true;
        state.errors.session = null;
      })
      .addCase(loginWithApple.fulfilled, (state, { payload }) => {
        state.pending.session = false;
        state.session = payload;
        state.access_token = JSON.parse(atob(payload.token.split('.')[1]));
      })
      .addCase(
        loginWithApple.rejected,
        (state, action: any & { payload: any }) => {
          state.pending.session = false;
          state.errors.session = action.payload.message;
        }
      )
      // ============ GET USER ============ //
      .addCase(getUser.pending, (state) => {
        state.pending.user = true;
        state.errors.user = null;
      })
      .addCase(getUser.fulfilled, (state, { payload }) => {
        state.pending.user = false;
        state.user = payload;
      })
      .addCase(getUser.rejected, (state, action: any & { payload: any }) => {
        state.pending.user = false;
        state.errors.user = action.payload.message;
      })
      // ============ UPDATE USER ============ //
      .addCase(updateUser.pending, (state) => {
        state.pending.user = true;
        state.errors.user = null;
      })
      .addCase(updateUser.fulfilled, (state, { payload }) => {
        state.pending.user = false;
        if (!state.user) {
          return;
        }
        state.user = Object.assign<GetUserDto, GetUserDto, Partial<UserDto>>(
          {} as any,
          state.user,
          { birthday: payload.birthday, username: payload.username }
        );
      })
      .addCase(updateUser.rejected, (state, action: any & { payload: any }) => {
        state.pending.user = false;
        state.errors.user = action.payload.message;
      })
      // ============ UPDATE USER PAYMENT METHOD ============ //
      .addCase(updateUserPaymentMethod.pending, (state) => {
        state.pending.user = true;
        state.errors.user = null;
      })
      .addCase(updateUserPaymentMethod.fulfilled, (state, { payload }) => {
        state.pending.user = false;
        if (!state.user) {
          return;
        }
        state.user = Object.assign<GetUserDto, GetUserDto, Partial<UserDto>>(
          {} as any,
          state.user,
          { defaultPaymentMethodId: payload.defaultPaymentMethodId }
        );
      })
      .addCase(
        updateUserPaymentMethod.rejected,
        (state, action: any & { payload: any }) => {
          state.pending.user = false;
          state.errors.user = action.payload.message;
        }
      )
      // ============ DELETE USER ============ //
      .addCase(deleteUser.pending, (state) => {
        state.pending.user = true;
        state.errors.user = null;
      })
      .addCase(deleteUser.fulfilled, (state) => {
        state.pending.user = false;
        state.user = null;
        state.session = null;
      })
      .addCase(deleteUser.rejected, (state, action: any & { payload: any }) => {
        state.pending.user = false;
        state.errors.user = action.payload.message;
      })
      // ============ GET SUBSCRIPTIONS ============ //
      .addCase(getSubscriptions.pending, (state) => {
        state.pending.subscriptions = true;
        state.errors.subscriptions = null;
      })
      .addCase(getSubscriptions.fulfilled, (state, { payload }) => {
        state.pending.subscriptions = false;
        state.subscriptions = payload && !payload.length ? null : payload;
      })
      .addCase(
        getSubscriptions.rejected,
        (state, action: any & { payload: any }) => {
          state.pending.subscriptions = false;
          state.errors.subscriptions = action.payload.message;
        }
      )
      // ============ CANCEL SUBSCRIPTIONS ============ //
      .addCase(cancelSubscriptions.pending, (state) => {
        state.pending.cancelSubscriptions = true;
        state.errors.cancelSubscriptions = null;
      })
      .addCase(cancelSubscriptions.fulfilled, (state, { payload }) => {
        state.subscriptions =
          state.subscriptions?.filter((d) => d.rollId !== payload) || null;
      })
      .addCase(
        cancelSubscriptions.rejected,
        (state, action: any & { payload: any }) => {
          state.pending.cancelSubscriptions = false;
          state.errors.cancelSubscriptions = action.payload.message;
        }
      );
  },
});

export const { cancelPending } = authSlice.actions;
