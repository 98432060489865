import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './app';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import './i18n';
import './index.css';
import { HelmetProvider } from 'react-helmet-async';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <HelmetProvider>
    <App />
  </HelmetProvider>
);

serviceWorkerRegistration.register();

reportWebVitals();
