import { useNavigate, useSearchParams } from 'react-router-dom';

interface NavigateWithQueryProps {
  route: string;
  param: string;
  value: string;
}

const useQueryParams = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const getQueryParam = (param: string): string | null => {
    return searchParams.get(param);
  };

  const navigateWithQuery = ({
    route,
    param,
    value,
  }: NavigateWithQueryProps): void => {
    navigate({
      pathname: route,
      search: `?${param}=${value}`,
    });
  };


  const removeQueryParam = (param: string): void => {
    setSearchParams((params) => {
      params.delete(param);
      return params;
    });
  };

  return {
    getQueryParam,
    navigateWithQuery,
    removeQueryParam,
  };
};

export default useQueryParams;
