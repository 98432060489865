export interface Asset {
  id: string;
  contentId: string;
  createdAt: string;
  videoLength: number;
  rollId: string;
  content: string;
  type: 'video' | 'image';
  assetType: CreatorAssetType;
  rows: number;
  cols: number;
  locked: boolean;
}

export interface PreviewAsset {
  id: string;
  contentId: string;
  createdAt: string;
  videoLength: number;
  rollId: string;
  content: string;
  type: 'video' | 'image';
  assetType: CreatorPreviewAssetType;
  rows: number;
  cols: number;
  locked: boolean;
}

export interface AssetMessage {
  id: string;
  contentId: string;
  assets: MessageAsset[];
}

export interface MessageAsset {
  assetId: string;
  assetUrl: string;
  assetType: MessageAssetType;
  id: string;
  mediaFormat: string;
  mediaType: 'video' | 'image';
  videoLengthSeconds: number;
}

export enum MessageAssetType {
  SMALL = 's',
  SMALL_BLURRED = 's_blurred',
  ORIGINAL = 'original',
  GALLERY = 'gallery',
}

export enum CreatorPreviewAssetType {
  BIG_PREVIEW = 'creator_big_content_preview',
  SMALL_PREVIEW = 'creator_small_content_preview',
  ORIGINAL = 'original',
  GALLERY = 'gallery',
  LARGE = 'l',
}

export enum CreatorAssetType {
  BIG_BLURRED = 'creator_big_content_blurred',
  SMALL_BLURRED = 'creator_small_content_blurred',
  ORIGINAL = 'original',
  GALLERY = 'gallery',
}
