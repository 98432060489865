import { colors } from 'constants/colors';
import { ZIndexes } from 'enums/z-indexes';
import { IconButton, Typography, styled } from '@mui/material';
import { WheelEvent, useCallback, useRef, useState } from 'react';

import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import AvatarEditor from 'react-avatar-editor';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const StyledTitle = styled(Typography)(({ theme }) => ({
  lineHeight: '44px',
  fontSize: '28px',
  fontWeight: '900 ',
  fontFamily: 'Avenir',
  fontStyle: 'normal',
  color: colors.default.info,
  textAlign: 'center',
  [theme.breakpoints.down('md')]: {
    fontSize: '24px',
  },
}));

const CropContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '80dvh',
  minWidth: '375px',
  [theme.breakpoints.down(375)]: {
    minWidth: '100%',
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  '> .MuiSvgIcon-root': {
    fontSize: '32px',
  },
  [theme.breakpoints.down('md')]: {
    '> .MuiSvgIcon-root': {
      fontSize: '24px',
    },
  },
}));

interface CropPictureModalProps {
  file: File;
  onClose: () => void;
  udpatePicture: (croppedFile: File) => void;
}

const CropPictureModal = (props: CropPictureModalProps) => {
  const { file, onClose, udpatePicture } = props;

  const editor = useRef<AvatarEditor>(null);
  const [zoom, setZoom] = useState<number>(1);

  const handleScroll = useCallback((e: WheelEvent<HTMLDivElement>) => {
    if (e.deltaY > 0) {
      setZoom((prevZoom) => Math.max(prevZoom - 0.1, 1));
    }
    if (e.deltaY < 0) {
      setZoom((prevZoom) => Math.min(prevZoom + 0.1, 5));
    }
  }, []);

  const handleUpdatePicture = useCallback(() => {
    if (!editor || !editor.current) {
      return;
    }

    const editedCanvas = editor.current.getImageScaledToCanvas();
    editedCanvas.toBlob((blob) => {
      if (blob) {
        const editedFile = new File([blob], file.name, {
          type: file.type,
          lastModified: Date.now(),
        });
        udpatePicture(editedFile);
      }
    }, file.type);
  }, [file, udpatePicture]);

  return (
    <Modal
      open={true}
      onClose={onClose}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: colors.default.black,
          },
        },
      }}
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: ZIndexes.External_Top_First,
      }}
    >
      <>
        <CropContainer onWheel={handleScroll}>
          <AvatarEditor
            ref={editor}
            image={file}
            width={374}
            height={374}
            border={[10, 20]}
            borderRadius={200}
            color={[0, 0, 0, 0.5]}
            scale={zoom}
          />
          <Grid
            marginTop="auto"
            display="flex"
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <StyledIconButton onClick={onClose}>
              <CloseIcon />
            </StyledIconButton>
            <StyledTitle>Profile photo</StyledTitle>
            <StyledIconButton onClick={handleUpdatePicture}>
              <CheckIcon />
            </StyledIconButton>
          </Grid>
        </CropContainer>
      </>
    </Modal>
  );
};

export default CropPictureModal;
