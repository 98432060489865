import { PaletteColorOptions, createTheme } from '@mui/material/styles';
import { colors } from 'constants/colors';

declare module '@mui/material/styles' {
  interface CustomPalette {
    ['white']?: PaletteColorOptions;
    ['green']?: PaletteColorOptions;
    ['dark-gray']?: PaletteColorOptions;
  }
  interface Palette extends CustomPalette { }
  interface PaletteOptions extends CustomPalette { }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    ['white']: true;
    ['green']: true;
  }

  interface ButtonPropsVariantOverrides {
    ['social-auth']: true;
    ['contained-dark']: true;
    ['card-button-dark']: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    ['white']: true;
    ['green']: true;
  }
}

declare module '@mui/material/FormControl' {
  interface FormControlPropsColorOverrides {
    ['white']: true;
  }
}

declare module '@mui/material/TextField' {
  interface TextFieldPropsColorOverrides {
    ['white']: true;
  }
  interface TextFieldPropsSizeOverrides {
    ['large']: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    ['dark-gray']: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsColorOverrides {
    ['dark-white']: true;
  }
  interface TypographyPropsVariantOverrides {
    ['error-payment']: true;
    ['new-messages']:true;
  }
}

declare module '@mui/material/ListItemButton' {
  interface ListItemButtonBaseProps {
    ['size']?: 'small' | 'medium' | 'large';
    ['variant']?: 'text' | 'navigation' | 'card-action' | 'navigate-creator';
  }
}


export const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Avenir-Regular',
      fontStyle: 'normal'
    },
    h1: {
      fontSize: '1.8rem',
      fontWeight: 800
    },
    h2: {
      fontSize: '1.6rem',
      fontWeight: 800
    },
    h3: {
      fontSize: '1.4rem',
      fontWeight: 800
    },
    h4: {
      fontSize: '1.2rem',
      fontWeight: 800
    },
    h5: {
      fontSize: '1.1rem',
      fontWeight: 800
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 800
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 500
    },
    body2: {
      fontSize: '0.8rem',
      fontWeight: 500
    },
    button: {
      fontSize: '1rem',
      fontWeight: 800
    },
  },
  palette: {
    mode: 'dark',
    error: {
      main: colors.default.error
    },
    primary: {
      main: colors.default.primary,
    },
    secondary: {
      main: colors.default.secondary
    },
    info: {
      main: colors.default.info
    },

    // custom styles
    white: {
      main: colors.default.white,
      contrastText: colors.default.whiteText
    },
    'dark-gray': {
      main: colors.default.darkGrey,
      contrastText:colors.default.white
    },
    green: {
      contrastText: colors.default.darkGrey,
      main: colors.default.green
    },
    background: {
      default: colors.default.background,
      paper: colors.default.black
    },
    text: {
      disabled: colors.default.textDisabled,
      primary: colors.default.primaryText,
      secondary: colors.default.primaryText
    }
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: '0 !important',
          position: 'relative'
        }
      }
    },
    MuiTypography: {
      variants: [
        {
          props: { color: 'white' },
          style: {
            color: colors.default.white,

            '& .subtitle': {
              color:colors.default.subtitleGrey
            }
          }
        },
        {
          props: { color: 'dark-white' },
          style: {
            color: colors.default.darkWhite
          }
        },
        {
          props:{ variant:'subtitle1' },
          style:{
            lineHeight: '24px',
            fontSize: '1rem',
            fontWeight: '500',
            fontFamily: 'Avenir-Regular',
            fontStyle: 'normal',
            textAlign: 'center',
          } 
        },
        {
          props:{ variant:'subtitle2' },
          style:{
            lineHeight: '19px',
            fontSize: '14px',
            fontWeight: '400',
            fontFamily: 'Avenir-Regular',
            color:colors.default.info,
            opacity:0.5
          }
        },
        {
          props: { variant: 'h6' },
          style: {
            '& .title': {
              fontWeight: 800
            },

            '& .subtitle': {
              fontSize: '0.85rem',
              fontWeight: 500
            }
          }
        },{
          props: { variant: 'error-payment' },
          style: {
            fontFamily:'Modak',
            fontWeight:400,
            lineHeight:"36px",
            fontSize:'46px',
            textAlign:'center',
            color: colors.default.white,
            textShadow:`0px 4px 4px ${colors.default.textShadow}`,
            WebkitTextStroke: `2px ${colors.default.error}`
          }
        },
        {
          props: { variant: 'new-messages' },
          style: {
            fontFamily: 'Avenir-Regular',
            color: colors.default.green,
            lineHeight: '19px',
            fontSize: '14px',
          }
        }
      ],
      styleOverrides: {
        root: {
          '&.Modak': {
            fontFamily: 'Modak',
          },

          '&.color-white': {
            color: colors.default.white,
            textShadow: `0px 4px 4px ${colors.default.textShadow}`,
            WebkitTextStroke: `0.5px ${colors.default.primaryText}`
          },

          '&.color-transparent': {
            color: 'transparent',
            textShadow: `0px 4px 4px ${colors.default.textShadow}`,
            WebkitTextStroke: `1px ${colors.default.white}`
          },


          '&.header': {
            fontFamily: 'Modak',
            fontSize: '46px',
            color: colors.default.info,
            textShadow: `0px 4px 4px ${colors.default.textShadow}`,
            lineHeight: '35px',
            textTransform: 'uppercase',
            marginBottom: '16px'
          },
          
          '&.color-green': {
            color: colors.default.green,
          },

          '& .title': {
            display: 'block',
            textTransform: 'capitalize'
          },

          '& .subtitle': {
            display: 'block',
            textTransform: 'none'
          },

          '&.transform-capitalize': {
            textTransform: 'capitalize'
          },

          '&.transform-uppercase': {
            textTransform: 'uppercase'
          },

          '&.transform-none': {
            textTransform: 'none'
          }
        }
      }
    },
    MuiIconButton: {
      variants: [
        {
          props: { color: 'green', disabled: false },
          style: {
            '&.button-outlined': {
              border: `1px solid ${colors.default.green}`,
              color: colors.default.green
            }
          }
        },
        {
          props: { color: 'green', disabled: true },
          style: {
            '&.button-outlined': {
              border: `1px solid ${colors.default.greenDisabled}`,
              color: colors.default.greenDisabled
            }
          }
        }
      ]
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'social-auth' as any, color: 'white' },
          style: {
            background: colors.default.white,
            color: colors.default.whiteText,
            textTransform: 'capitalize',

            '&:hover': {
              background: colors.default.white,
            }
          }
        },
        {
          props: { variant: 'contained' as any, color: 'green', disabled: false },
          style: {
            background: `${colors.default.green} !important`,
            color: `${colors.default.darkGrey} !important`
          }
        },
        {
          props: { variant: 'contained' as any, color: 'green', disabled: true },
          style: {
            background: `${colors.default.greenDisabled} !important`,
            color: `${colors.default.darkGreyDisabled} !important`
          }
        },
        {
          props: { variant: 'contained-dark' as any, color: 'green', disabled: false },
          style: {
            background: `${colors.default.transactionsBackground} !important`,
            color: `${colors.default.green} !important`,
            border: `2px solid ${colors.default.thinBlack} !important`
          }
        },
        {
          props: { variant: 'contained-dark' as any, color: 'green', disabled: true },
          style: {
            background: `${colors.default.darkGrey} !important`,
            color: `${colors.default.greenDisabled} !important`,
            border: `2px solid ${colors.default.blackDisabled} !important`
          }
        },
        {
          props: { variant: 'card-button-dark' as any, color: 'green', disabled: false },
          style: {
            border: `1px solid ${colors.default.borderLightGray}`,
            background: `${colors.default.black} !important`,
            borderRadius: "9px",
            flex: "1 1",
            justifyContent: "space-between",
            padding: "20px 17px",
          }
        },
        {
          props: { size: 'small' },
          style: {
            padding: '6px 12px',
            fontSize: '.85rem'
          }
        }
      ],
      styleOverrides: {
        root: {
          fontFamily: 'Avenir',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 800,
          borderRadius: '29px',
          boxShadow: 'none !important',

          padding: '12px',

          '&.transform-capitalize': {
            textTransform: 'capitalize'
          },

          '&.transform-uppercase': {
            textTransform: 'uppercase'
          },

          '&:hover': {
            filter: 'contrast(1.2)'
          }
        }
      }
    },
    MuiFormControl: {
      variants: [

      ],
      styleOverrides: {
        root: {
          flex: '1 1',
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiButtonBase-root': {
            color: colors.default.thinWhite,
            fill: colors.default.thinWhite,
            fontSize: '17px',

            '&.Mui-selected': {
              color: colors.default.white,

              '& svg': {
                fill: colors.default.white
              }
            }
          },

          '& .MuiTab-root': {
            textTransform: 'capitalize'
          },

          '& .MuiTab-root:hover': {
            color: colors.default.white,

            '& svg': {
              fill: colors.default.white
            }
          },

          '& .MuiTabs-indicator': {
            backgroundColor: colors.default.info
          }
        }
      }
    },
    MuiModal: {
      styleOverrides: {
        root: {
          zIndex: 10000,
          display: 'flex',

          '& .MuiBox-root.modal-box': {
            border: `5px solid ${colors.default.secondary}`,
            borderRadius: '40px',
            padding: '28px 16px',
            background: colors.default.background,
            margin: 'auto 20px',
            textAlign: 'center',

            '& .MuiDivider-root': {
              margin: '26px 0',
              color: colors.default.borderLightGray,
              background: colors.default.borderLightGray
            },

            '& h2': {
              color: colors.default.info,
              fontSize: '28px'
            },

            '& p': {
              color: '#A5A4A2',
              fontSize: '16px'
            }
          },

          '& .MuiBox-root.modal-confirm': {
            border: `5px solid ${colors.default.secondary}`,
            borderRadius: '40px',
            padding: '28px 0 0',
            background: colors.default.background,
            margin: 'auto 20px',

            '& .MuiDivider-root': {
              margin: '26px 0',
              color: colors.default.borderLightGray,
              background: colors.default.borderLightGray
            },

            '& h2': {
              color: colors.default.info,
              fontSize: '28px'
            },

            '& p': {
              color: '#A5A4A2',
              fontSize: '16px'
            },

            '& .btn-confirm': {
              color: colors.default.green,
              fontSize: '16px',
              padding: '26px 24px',
              flex: '1 1'
            },

            '& .btn-cancel': {
              color: colors.default.info,
              fontSize: '16px',
              padding: '26px 24px',
              flex: '1 1'
            },

            '& .modal-actions': {
              display: 'flex',
              justifyContent: 'space-around',

              '& hr': {
                width: '2px',
                background: '#373737',
                margin: '1px'
              }
            }
          }
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: '8px 0 0 8px',
          marginTop: '-4px',

          '& .MuiSwitch-thumb': {
            background: colors.default.info,
            width: '28px',
            height: '28px'
          },

          '& .MuiSwitch-track': {
            borderRadius: '15.5px'
          },

          '& .Mui-checked + .MuiSwitch-track': {
            background: colors.default.greenSwitch
          }
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          '& .MuiDrawer-paper': {
            width: '90%',
            maxWidth: '300px'
          }
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          padding: '2px 20px !important'
        }
      }
    },
    MuiListItemIcon: {
      variants: [
        {
          props: { color: 'green' },
          style: {
            color: colors.default.green
          }
        }
      ]
    },
    MuiListItemButton: {
      variants: [
        {
          props: { variant: 'navigate-creator' },
          style: {
            '& .content-wrap': {
              borderBottom: `2px solid ${colors.default.dividerColor}`,
              display: "flex",
              alignItems: "center",
              width: "100%",
            },

            '& .MuiListItemText-primary': {
              fontSize: '20px',
              fontFamily: 'Modak',
              textTransform: 'uppercase',
              color: colors.default.darkGrey,
              textShadow: `0px 4px 4px ${colors.default.textShadow}`,
              WebkitTextStroke: `1.5px ${colors.default.white}`
            },

            '& .MuiListItemText-secondary': {
              fontSize: '14px',
              lineHeight: '19px',
              color: colors.default.darkWhite
            },

            '&:hover': {
              background: 'rgba(0, 0, 0, .2)'
            }
          }
        },
        {
          props: { variant: 'text' },
          style: {
            '& .MuiListItemText-primary': {
              fontWeight: 800
            }
          }
        },
        {
          props: { color: 'green' },
          style: {
            '& .MuiListItemText-primary': {
              color: colors.default.green
            },

            '& .MuiListItemText-secondary': {
              color: colors.default.subtitleGrey
            }
          }
        },
        {
          props: { size: 'small', variant: 'text' },
          style: {
            '& .MuiListItemText-primary': {
              fontSize: '1rem'
            }
          }
        },
        {
          props: { size: 'medium', variant: 'text' },
          style: {
            '& .MuiListItemText-primary': {
              fontSize: '1.2rem',
            }
          }
        },
        {
          props: { size: 'large', variant: 'text' },
          style: {
            '& .MuiListItemText-primary': {
              fontSize: '1.4rem',
            }
          }
        },
        {
          props: { color: 'dark-gray' },
          style: {
            '& .MuiListItemText-primary': {
              color: colors.default.darkGrey
            }
          }
        },
        {
          props: { color: 'white' },
          style: {
            '& .MuiListItemText-primary': {
              color: colors.default.white
            }
          }
        },
        {
          props: { variant: 'navigation' },
          style: {
            '& .MuiListItemText-primary': {
              textTransform: 'capitalize'
            },

            '& .MuiListItemText-secondary': {
              textTransform: 'capitalize'
            }
          }
        },
        {
          props: { variant: 'navigation', size: 'medium' },
          style: {
            '& .MuiListItemIcon-root svg': {
              fontSize: '1rem'
            },

            '& .MuiListItemText-primary': {
              fontSize: '.85rem'
            },

            '& .MuiListItemText-secondary': {
              fontSize: '1rem'
            }
          }
        },
        {
          props: { variant: 'navigation', color: 'white' },
          style: {
            '& .MuiListItemText-primary': {
              color: colors.default.subtitleGrey
            },

            '& .MuiListItemText-secondary': {
              color: colors.default.white
            }
          }
        },
        {
          props: { variant: 'card-action' },
          style: {
            padding: '10px 8px !important',
            borderBottom: `1px solid ${colors.default.cardSelectionBorder}`,

            '& .MuiListItemText-primary': {
              display: 'flex',
              alignItems: 'center',
              borderBottom: `1px solid ${colors.default.cardSelectionBorder}`
            },

            '& .MuiListItemText-secondary': {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between', padding: '4px 0 0'
            }
          }
        }
      ]
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          fontSize: '0.8rem',
          color: colors.default.darkWhite,
          fontWeight: 800,
          letterSpacing: '-0.259371px',
          fontFamily: 'Avenir-Regular'
        }
      }
    },
    MuiTextField: {
      variants: [
        {
          props: { size: 'large' },
          style: {
            '& .MuiInput-root': {
              fontSize: '1.2rem'
            }
          }
        },
        {
          props: { color: 'white' },
          style: {
            '& label': {
              color: colors.default.darkWhite
            },

            '& label.Mui-focused': {
              color: colors.default.white
            },

            '& .MuiInput-root': {
              color: colors.default.darkWhite
            },

            '& .MuiInput-root .MuiInput-input::-webkit-input-placeholder': {
              color: colors.default.darkWhite
            },

            '& .MuiInput-root .MuiInput-input::-ms-input-placeholder': {
              color: colors.default.darkWhite
            },

            '& .MuiInput-root .MuiInput-input::-webkit-calendar-picker-indicator': {
              colorScheme: 'dark'
            },

            '& .MuiInput-root::after': {
              borderBottom: `2px solid ${colors.default.darkWhite}`,
            },

            '& .MuiInput-root.Mui-focused': {
              color: colors.default.white
            },

            '& .MuiInput-root.Mui-focused::after': {
              borderBottom: `2px solid ${colors.default.white}`,
            },

            '&:hover .MuiInput-root::after': {
              borderBottom: `2px solid ${colors.default.white} !important`,
            },

            '&:hover .MuiInput-root::before': {
              borderBottom: `2px solid ${colors.default.white} !important`,
            },

          }
        },
        {
          props: { variant: 'standard' },
          style: {
            '& label': {
              textTransform: 'capitalize',
              fontSize: '0.95rem',
              transform: 'translate(0, -1.5px) scale(0.75);',
              letterSpacing: '0.240629px'
            }
          }
        }
      ],
      styleOverrides: {
        root: {
          flex: '1 1',

          '& .MuiInput-root': {
            fontSize: '1rem'
          },

          '& .MuiInput-root.Mui-error': {
            color: colors.default.error
          }
        },
      }
    }
  }
}, {

});

export type AppTheme = typeof theme & { colors: typeof colors }

export default theme