import axios from 'axios';
import { ACCESS_TOKEN, DEVICE_ID, SESSION } from 'constants/local-storage';
import { firebaseSignOut } from 'firebase';
import { debounce } from 'lodash';

import { v4 as uuidv4 } from 'uuid';

export let deviceId = localStorage.getItem(DEVICE_ID);
if (!deviceId) {
  deviceId = uuidv4();
  localStorage.setItem(DEVICE_ID, deviceId);
}

const client = axios.create({
  baseURL: process.env.REACT_APP_API_SERVER,
  headers: {
    "Content-Type": "application/json",
  },
});

const refreshPendingList: { resolve: (payload: any) => any, reject: (payload: any) => any }[] = [];
let isRefreshPending = false;

const refreshUserToken = async ({ session, access_token }: any) => {
  if (!isRefreshPending) {
    isRefreshPending = true;

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_SERVER}/users/refresh-token`,
        {
          refreshToken: JSON.parse(localStorage.getItem(SESSION) as string)
            .refreshToken,
          userId: session.sub.userId,
          deviceId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${access_token}`,
          },
        }
      );
      localStorage.setItem(ACCESS_TOKEN, data.token);

      refreshPendingList.forEach(({ resolve }) => resolve({ token: data.token }));
      refreshPendingList.splice(0);
      isRefreshPending = false;

      return Promise.resolve<{ token?: any, error?: any }>({ token: data.token });
    } catch (error) {
      refreshPendingList.forEach(({ reject }) => reject({ error }));
      refreshPendingList.splice(0);
      isRefreshPending = false;
      logOut()

      return Promise.reject<{ token?: any, error?: any }>({ error });
    }
  }

  return new Promise<{ token?: any, error?: any }>((resolve, reject) => {
    refreshPendingList.push({ resolve, reject });
  });
};

client.interceptors.request.use(
  async (config) => {
    const access_token = localStorage.getItem(ACCESS_TOKEN);
    if (!access_token) { return config; }

    config.headers.Authorization = `${access_token}`;

    const session = JSON.parse(
      atob((access_token as string).split(".")[1])
    );

    if (!session) { return config; }

    // === refresh token expiration check ===
    if (session.exp - Date.now() / 1000 > 30) { return config; }

    // === refresh token callback ===========
    const { token, error } = await refreshUserToken({ session, access_token });
    if (error) { return config; }

    config.headers.Authorization = `${token}`;

    // return !refreshed ? config : refreshed;
    return config;
  },
  (error) => Promise.reject(error)
);

client.interceptors.response.use(
  (config) => config,
  (error) => {
    if ([401].includes(error?.response?.status)) {
      logOut();
    }

    return Promise.reject(error);
  }
);

export const check_username = debounce(async (username: string) => {
  try {
    const { status } = await client.get(`/users/usernames/${username}`);
    return status === 200 ? false : true;
  } catch (error) {
    return true;
  }
}, 200);

export const logOut = () => {
  firebaseSignOut();
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(SESSION);
  window.location.href = window.location.origin;
};

export default client;
