import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { GoogleOAuthProvider } from '@react-oauth/google';

import themes from './themes';

import './app.css';

import AppRoutes from 'app.routes';

import store from 'store';
import ErrorBoundary from 'components/error-boundary';
import SEO from 'components/seo-component';
import HeadImage from 'assets/images/head.image.svg';

function App() {
  return (
    <ThemeProvider theme={themes}>
      <ErrorBoundary>
        <GoogleOAuthProvider
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}
        >
          <SEO
            description="Explore the uncharted territory of your favorite creators with Roll! Get exclusive behind-the-scenes content, enjoy 1-on-1 private messaging, and immerse yourself in authentic connections. The ultimate inside look awaits!"
            image={HeadImage}
            title="Unlock Exclusive Content with Roll 👀"
            type="website"
          />
          <Provider store={store}>
            <Router>
              <AppRoutes />
            </Router>
          </Provider>
        </GoogleOAuthProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;
