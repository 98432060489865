import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { RollLogoMiniImage } from 'assets/images';
import { Typography, styled } from '@mui/material';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import UserDrawer from './user-drawer';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Toolbar from '@mui/material/Toolbar';
import useQueryParams from 'hooks/use-query-params';
import IconButton from '@mui/material/IconButton';

import { RoutesList } from 'enums/routes-list.enum';
import {
  ChatActiveIcon,
  ChatIcon,
  ProfileActiveIcon,
  ProfileIcon,
  RollActiveIcon,
  RollIcon,
} from 'assets/icons';
import { useTranslation } from 'react-i18next';
import { GetUserDto } from 'types/get-user.type';
import { MENU_OPEN } from 'constants/frontend-contsants';

const VerticalTabs = styled(Tabs)({
  '.MuiTabs-indicator': {
    display: 'none',
  },

  '& .MuiTabs-flexContainer': {
    display: 'flex',
    flexDirection: 'column',

    '& button': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start !important',

      fontFamily: 'Avenir-Regular',
      fontSize: '23px',
      fontWeight: 500,

      '& svg': {
        width: '30px',
        height: '30px',
        marginRight: '30px',
      },
    },
  },
});

const VerticalToolbar = styled(Toolbar)(({ theme }) => ({
  display:'none',
  [theme.breakpoints.up('md')]: {
    display:'flex',
  },
  [theme.breakpoints.between('md', 1000)]: {
    paddingLeft: '8px',
    paddingRight: '8px',
  },
}));

const StyledAppBar = styled(AppBar)({
  boxShadow: 'none',
  background: '#272727',
  width: 'auto',
});

const StyledTab = styled(Tab)({
  '&.MuiButtonBase-root': {
    textTransform: 'capitalize',
  },
});

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  justifyContent: 'space-between',
  padding: '0 12px',

  [theme.breakpoints.up('md')]: {
    justifyContent: 'flex-start',
    padding: '25px 0 15px 12px',
  },
}));

const ToolbarForTabs = styled(Toolbar)(({ theme }) => ({
  justifyContent: 'center',
  padding: '0 12px',
  minHeight: '34px',

  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

const AvatarIconButton = styled(IconButton)(({ theme }) => ({
  padding: '2px 12px',

  '&:hover': {
    background: 'none',
  },

  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

const LogoIconButton = styled(IconButton)(({ theme }) => ({
  mr: 2,
  '&:hover': {
    background: 'none',
  },
  [theme.breakpoints.up('md')]: {
    paddingLeft: 0,
  },
}));

const StyledDivider = styled('span')(({ theme }) => ({
  width: '52px',
  background: 'none',
  cursor: 'initial',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

interface AppHeaderProps {
  display?: any;
  user: GetUserDto | null;
}

const AppHeader: FC<AppHeaderProps> = ({ user, display = 'flex' }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [opened_drawer, setOpenedDrawer] = useState(false);

  const { getQueryParam, removeQueryParam } = useQueryParams();

  const navigateMain = () => navigate(RoutesList.Creators_Default);
  const navigateMessages = () => navigate(RoutesList.Messages_Default);
  const navigateProfile = () => navigate(RoutesList.Profile_Default);

  const handleOpenDrawer = () => setOpenedDrawer(true);
  const handleCloseDrawer = () => setOpenedDrawer(false);

  const index = useMemo(() => {
    if (pathname.startsWith(RoutesList.Messages_Default)) {
      return 1;
    }

    if (pathname.startsWith(RoutesList.Profile_Default)) {
      return 2;
    }

    return 0;
  }, [pathname]);

  useEffect(() => {
    if (getQueryParam(MENU_OPEN)) {
      setOpenedDrawer(true);
      removeQueryParam(MENU_OPEN);
    }
  }, [getQueryParam, removeQueryParam]);

  return (
    <>
      <StyledAppBar position="sticky" color="transparent" sx={{ display }}>
        <StyledToolbar variant="dense" color="info">
          <StyledDivider />
          <LogoIconButton
            color="inherit"
            aria-label="back"
            onClick={navigateMain}
          >
            <RollLogoMiniImage width="142px" height="28px" />
          </LogoIconButton>
          <AvatarIconButton
            color="inherit"
            edge="end"
            aria-label="back"
            onClick={handleOpenDrawer}
          >
            <Avatar
              sx={{ width: '44px', height: '44px', background: '#3A3A3A' }}
              src={user?.profilePictureUrl}
            >
              {user && (
                <Typography
                  className="Modak color-white"
                  variant="h2"
                  component="span"
                  color="white"
                  textTransform="uppercase"
                  sx={{ paddingTop: '4px' }}
                >
                  {user.username ? user.username.substring(0, 1) : ''}
                </Typography>
              )}
            </Avatar>
          </AvatarIconButton>
        </StyledToolbar>
        {index < 2 && (
          <ToolbarForTabs variant="dense" color="info">
            <Tabs
              value={index}
              aria-label="creators tabs"
              sx={{ minHeight: '34px' }}
            >
              <StyledTab
                label={t('navigations.creators')}
                onClick={navigateMain}
              />
              <StyledTab
                label={t('navigations.messages')}
                onClick={navigateMessages}
              />
            </Tabs>
          </ToolbarForTabs>
        )}
        <VerticalToolbar>
          <VerticalTabs value={index} aria-label="rolls tabs">
            <StyledTab
              label={t('navigations.rolls')}
              icon={
                index === 0 ? <RollActiveIcon /> : <RollIcon color="inherit" />
              }
              onClick={navigateMain}
            />
            <StyledTab
              label={t('navigations.messages')}
              icon={
                index === 1 ? <ChatActiveIcon /> : <ChatIcon color="inherit" />
              }
              onClick={navigateMessages}
            />
            <StyledTab
              label={t('navigations.profile')}
              icon={
                index === 2 ? (
                  <ProfileActiveIcon />
                ) : (
                  <ProfileIcon color="inherit" />
                )
              }
              onClick={navigateProfile}
            />
          </VerticalTabs>
        </VerticalToolbar>
      </StyledAppBar>
      <UserDrawer
        opened={opened_drawer}
        user={user}
        handleOpen={handleOpenDrawer}
        handleClose={handleCloseDrawer}
      />
    </>
  );
};

export default AppHeader;
