export const colors = {
  default: {
    primary: '#ACFB68',
    primaryText: '#272727',
    primaryPressed: '#ACFB68',
    primaryDsiabled: 'rgba(172, 251, 104, .6)',

    secondary: '#EF99E9',

    info: '#FFF',
    infoText: '#272727',

    background: '#272727',

    error: '#FD3D30',

    // custom styles

    textDisabled: '#25252599',

    green: '#ACFB68',
    greenDisabled: 'rgba(172, 251, 104, .6)',
    greenSwitch: '#4CD964',

    white: '#F3F3F3',
    whiteText: '#232323',

    thinWhite: 'rgba(255,255,255,0.4)',

    darkWhite: '#C7C6CB',
    darkWhiteDisabled: 'rgba(199, 198, 203, 0.6)',

    grey:'#9F9F9F',
    darkGrey: 'rgba(31, 31, 31, 1)',
    darkGreyDisabled: 'rgba(31, 31, 31, .6)',
    thinGrey: 'rgba(159, 159, 159, .5)',
    lightGrey: '#B5B9B4',

    black: '#000',
    thinBlack: 'rgba(0, 0, 0, 0.4)',
    blackDisabled: 'rgba(0, 0, 0, 0.2)',

    subtitleGrey: '#757575',
    textShadow: 'rgba(0, 0, 0, 0.25)',
    transactionsBackground: '#1F1F1F',
    usernameGrey: '#3D3D3D',

    borderLightGray: '#555',
    dividerColor: '#343434',
    cardSelectionBorder: '#3F3F3F',

    textArea: '#838489',
    textAreaBackground: '#272727',

    tipsBackground: 'rgba(172, 251, 104, 0.1)',
    tipsBlack: '#0C0C0C',

    messageText:"#E8E8E9"
  },
};
