export const createDateString = ()=>{
const currentDate = new Date();

const year = currentDate.getFullYear();
const month = String(currentDate.getMonth() + 1).padStart(2, '0');
const day = String(currentDate.getDate()).padStart(2, '0');

const hours = String(currentDate.getHours()).padStart(2, '0');
const minutes = String(currentDate.getMinutes()).padStart(2, '0');
const seconds = String(currentDate.getSeconds()).padStart(2, '0');

const timezoneOffset = -currentDate.getTimezoneOffset();

const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}+${timezoneOffset}`;

return formattedDate

}