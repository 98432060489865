import { styled } from "@mui/material";
import { colors } from "constants/colors";

export const GreenLink = styled('a')({
    textTransform: 'none',
    textDecoration: 'none',
    color: colors.default.green,
    fontFamily: 'Avenir-Regular',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    cursor:'pointer'
  });