import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { StripeWallet, WalletState } from 'app/auth/types/auth-state.type';

import {
  createPaymentMethods,
  deletePaymentMethods,
  getMessageCheckout,
  getPaymentMethods,
  getSubscriptionCheckout,
  viewTransactions,
} from './wallet.actions';
import { updateUserPaymentMethod } from 'app/auth/store/auth.actions';
import { CheckoutSessionDto } from 'types/checkout-session.dto';

const initialState: WalletState = {
  paymentMethod: null,
  cards: null,
  stripeWallet: null,
  checkoutSession: null,
  pending: {
    paymentMethod: false,
    cards: false,
    transactions: false,
    checkoutSession: false,
  },
  errors: {
    paymentMethod: null,
    cards: null,
    transactions: null,
    checkoutSession: null,
  },
};

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    resetPaymentMethod: (state) => {
      state.paymentMethod = null;
      state.errors.paymentMethod = null;
      state.pending.paymentMethod = false;
    },
    setStripeWallet: (state, { payload }: PayloadAction<StripeWallet>) => {
      state.stripeWallet = payload;
    },
    cleanWallet: (state) => {
      state.stripeWallet = null;
      state.checkoutSession = null;
      state.errors.checkoutSession = null;
      state.pending.checkoutSession = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // ============ CREATE PAYMENT METHODS ============ //
      .addCase(createPaymentMethods.pending, (state) => {
        state.pending.paymentMethod = true;
        state.errors.paymentMethod = null;
      })
      .addCase(createPaymentMethods.fulfilled, (state, { payload }) => {
        state.pending.paymentMethod = false;
        state.paymentMethod = payload;
      })
      .addCase(
        createPaymentMethods.rejected,
        (state, action: any & { payload: any }) => {
          state.errors.paymentMethod = action.payload.message;
          state.pending.paymentMethod = false;
        }
      )
      // ============ GET PAYMENT METHODS ============ //
      .addCase(getPaymentMethods.pending, (state) => {
        state.pending.cards = true;
        state.errors.cards = null;
      })
      .addCase(getPaymentMethods.fulfilled, (state, { payload }) => {
        state.cards = payload && !payload.length ? null : payload;
        state.pending.cards = false;
      })
      .addCase(
        getPaymentMethods.rejected,
        (state, action: any & { payload: any }) => {
          state.errors.cards = action.payload.message;
          state.pending.cards = false;
        }
      )
      // ============ DELETE PAYMENT METHODS ============ //
      .addCase(deletePaymentMethods.pending, (state) => {
        state.pending.cards = true;
      })
      .addCase(deletePaymentMethods.fulfilled, (state, { payload }) => {
        if (state.cards) {
          state.cards = state.cards.filter((d) => d.id !== payload);
        }
        state.pending.cards = false;
      })
      .addCase(
        deletePaymentMethods.rejected,
        (state, action: any & { payload: any }) => {
          state.errors.cards = action.payload.message;
          state.pending.cards = false;
        }
      )
      // ============ UPDATE USER PAYMENT METHOD ============ //
      .addCase(updateUserPaymentMethod.pending, (state) => {
        state.pending.paymentMethod = true;
        state.pending.cards = true;
      })
      .addCase(updateUserPaymentMethod.fulfilled, (state) => {
        state.pending.paymentMethod = false;
        state.pending.cards = false;
      })
      .addCase(
        updateUserPaymentMethod.rejected,
        (state, action: any & { payload: any }) => {
          state.pending.paymentMethod = false;
          state.pending.cards = false;
          state.errors.cards = action.payload.message;
        }
      )
      // ============ GET USER TRANSACTIONS ============ //
      .addCase(viewTransactions.pending, (state) => {
        state.pending.transactions = true;
      })
      .addCase(viewTransactions.fulfilled, (state) => {
        state.pending.transactions = false;
      })
      .addCase(
        viewTransactions.rejected,
        (state, action: any & { payload: any }) => {
          state.pending.transactions = false;
          state.errors.transactions = action.payload.message;
        }
      )
      // ============ CHECKOUT MESSAGE ============ //
      .addCase(getMessageCheckout.pending, (state) => {
        state.pending.checkoutSession = true;
        state.errors.checkoutSession = null;
      })
      .addCase(
        getMessageCheckout.fulfilled,
        (state, { payload }: PayloadAction<CheckoutSessionDto>) => {
          state.pending.checkoutSession = false;
          state.checkoutSession = payload;
        }
      )
      .addCase(
        getMessageCheckout.rejected,
        (state, action: any & { payload: any }) => {
          state.pending.checkoutSession = false;
          state.errors.checkoutSession = action.payload.message;
        }
      )
      .addCase(getSubscriptionCheckout.pending, (state) => {
        state.pending.checkoutSession = true;
        state.errors.checkoutSession = null;
      })
      .addCase(
        getSubscriptionCheckout.fulfilled,
        (state, { payload }: PayloadAction<CheckoutSessionDto>) => {
          state.pending.checkoutSession = false;
          state.checkoutSession = payload;
        }
      )
      .addCase(
        getSubscriptionCheckout.rejected,
        (state, action: any & { payload: any }) => {
          state.pending.checkoutSession = false;
          state.errors.checkoutSession = action.payload.message;
        }
      );
  },
});

export const { resetPaymentMethod, setStripeWallet, cleanWallet } =
  walletSlice.actions;
