import { createSlice } from '@reduxjs/toolkit';

import { ProfileState } from '../types/state.type';
import { updateProfilePicture } from './profile.actions';

const initialState: ProfileState = {
  pending: {
    picture:false
  },
  errors: {
    picture:null
  },
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // ============ GET CONVERSATIONS ============ //
      .addCase(updateProfilePicture.pending, (state) => {
        state.pending.picture = true;
        state.errors.picture = null;
      })
      .addCase(updateProfilePicture.fulfilled, (state) => {
        state.pending.picture = false;
      })
      .addCase(updateProfilePicture.rejected, (state, action: any & { payload: any }) => {
        state.pending.picture = false;
        state.errors.picture = action.payload.message;
      })
  },
});