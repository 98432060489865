import { database } from '../firebase';
import {
  ref,
  set,
  onDisconnect,
  onValue,
} from '@firebase/database';

interface PresenceHookProps {
  uid: string | null;
}

const usePresence = ({ uid }: PresenceHookProps) => {
    if (uid) {
      const userStatusDatabaseRef = ref(database, `/status/${uid}`);

      const isOfflineForDatabase = {
        state: 'offline',
        timestamp:new Date().toISOString(),
      };

      const isOnlineForDatabase = {
        state: 'online',
        timestamp:new Date().toISOString(),
      };

      const connectedRef = ref(database, '.info/connected');
      const presenceCleanup = () => {
        set(userStatusDatabaseRef, isOfflineForDatabase);
      };
      onValue(connectedRef, (snapshot) => {
        if (snapshot.val() === false) {
          return;
        }
        onDisconnect(userStatusDatabaseRef)
          .set(isOfflineForDatabase)
          .then(() => set(userStatusDatabaseRef, isOnlineForDatabase));
      });
      return presenceCleanup;
    }
};

export default usePresence;
